.partners
    padding-bottom: 2rem

    .partner-box
        margin-top: 2rem
        @media (max-width: 59rem)
            margin-top: 0rem

        .partners-container
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center

        .partner-heading
            margin: 0 auto
            max-width: 85%
            width: 19rem
            padding: .25rem
            background: #00585E
            box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.25)
            border-radius: 1rem

            .title-partner
                margin: 0 auto
                text-align: center
                font-size: 2rem
                font-weight: 700
                color: #FFF

        .logo-row
            margin-top: 2.25rem
            max-width: 80%

            .logo-box
                display: flex
                align-items: center
                max-width: 10rem
                height: 10rem
                -webkit-backdrop-filter: blur(5px)
                backdrop-filter: blur(5px)
                background: #00585E
                margin: 1.25rem 1.25rem
                border-radius: 1.25rem
                position: relative
                .partners-logo
                    max-width: 100%
                .partners-medal
                    position: absolute
                    bottom: -1rem
                    left: .1rem
                    max-width: 2.5rem

@media (max-width: 59rem)
    .partners
        .partner-box
            .logo-row
                max-width: 100%

@media (max-width: 48rem)
    .partners
        margin-top: 2rem
        .tbaContainer
            margin-right: 10%
            margin-left: 10%

@media (max-width: 26.5rem)
    .partners
        .partner-box

            .partner-heading
                .title
                    font-size: 1.5rem
            .logo-row
                .logo-box
                    margin-bottom: 3rem
        .tbaContainer
            margin-right: 5%
            margin-left: 5%

@media (max-width: 25rem)
    .partners
        .tbaContainer
            margin-right: 0%
            margin-left: 0%
