.resetContainer
  color: #FFFFFF
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column
  padding: 0rem 1rem 0rem 1rem
  .formBody
    width: 80%
    padding-top: 2rem
    display: flex
    flex-direction: column
    justify-content: center
    flex: 1 1
    h1
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        text-align: center
        margin-bottom: 2rem
        padding-top: 0
    .inputsReset
        .inputReset
            margin-top: 15%
            margin-bottom: 1rem
            .newPass
                margin-bottom: 10%
            .confirmNewPass
        .infoAlert
            margin-bottom: 1rem
  .resetBtn
    padding-left: 3rem
    padding-right: 3rem

@media screen and (max-width: 59.5rem)
    .inputsReset
        margin-bottom: 10%
