@import "../helpers/input/Input.sass"
@import "../helpers/buttons/button/Button.sass"

$border-radius: 1.5rem

.contactContainer
  display: flex
  width: 55%
  justify-content: center
  align-items: center
  margin: auto
  padding-bottom: 2rem
  .container
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    box-shadow: 0rem .25rem .5rem rgba(0, 0, 0, .25)
    gap: 3 rem
    background: white
    border-radius: $border-radius
    position: relative
    margin-left: 1rem
    margin-right: 1rem
    margin-top: 2rem
    margin-bottom: 2rem

    .contactTitle
      font-family: 'Montserrat', sans-serif
      font-weight: 600
      text-align: center
      padding: 2.5%
    .contactForm
      width: 100%
      padding: 4%

      .inputs
        margin-top: 2rem
        margin-bottom: -1rem
        .contactInput
            margin-bottom: 2rem
            width: 100%
            .inputContainer
                width: 100%
                &::after
                    left: 3rem
      .contactInput input
        font-family: 'Montserrat', sans-serif
        font-weight: 500
        height: 3.75rem
        border: 1px solid #00585E
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25)
        padding: .25rem
        position: relative
        &:after
          content: attr(floatingtext)
          position: absolute
          left: 1rem !important
          color: $color
          left: $padding-left - 0.4rem
          top: 50%
          transform: translateY(-5%)
          font-size: 12pt
          padding: 0rem .25rem
          transition: 200ms ease-in-out
          text-transform: capitalize
        
      .contactText
        resize: none
        color: #00585E
        border: .25rem solid #00585E
        box-sizing: border-box
        border-radius: 1.5rem
        margin: 1.25rem 0
        width: 100%
        min-height: 18.75rem
        padding-top: 2%
        padding-left: 3%
        font-family: 'Montserrat', sans-serif
        font-size: 1.25rem
        font-weight: 500
  .alert-color
    color: #DC143C
  .alert-ok
    color: #00585e
    transition: 0.5s
    text-align: center
    font-family: 'Montserrat', sans-serif
    font-weight: 400

.contactPageButton .buttonContainer 
  width: fit-content
  margin: .5rem auto 0rem
  display: block
  padding: .25rem 3rem
  font-size: 1.5rem
  font-weight: 500

@media screen and (max-width: 64rem)
  .contactContainer
    width: 90%
    .container
      .contactForm
        .contactInput input
        .contactText
          padding: 5%
        .buttonContainer
          padding: .25rem 1.5rem
          margin-bottom: 1rem
@media screen and (max-width: 59rem)
  .contactContainer
    .container
      background: rgba(0, 88, 94, .85)
      -webkit-backdrop-filter: blur(.25rem)
      backdrop-filter: blur(.25rem)
      .contactTitle
        font-size: 2.75rem
        color: #FFFFFF
      .contactForm
        .alert-ok
            color: #FFFFFF
            transition: 0.5s
        .inputs
            margin-top: 1rem
            margin-bottom: .75rem
            .contactInput
                margin-bottom: .5rem
                input
                    font-size: 1.25rem
                    height: 2.75rem
            .contactText
                padding: 5%

@media screen and (max-width: 47.5rem)
  .contactContainer
    .container
      width: 90%
      .contactTitle
        font-size: 2.5rem
        margin-left: -.75rem
      .contactForm
        .contactInput input
        .contactText
          padding: 7%
  .contactPageButton .buttonContainer 
    font-size: 1.25rem
    padding: .5rem 1.25rem
@media screen and (max-width: 31.25rem)
  .contactContainer
    .container
      .contactTitle
        padding-top: 1rem
        font-size: 2rem
        margin-left: -.75rem
      .contactForm
        .inputs
            margin-top: 1rem
            margin-bottom: .75rem
            .contactInput
                margin-bottom: .5rem
        text-align: center
        .contactInput input
        .contactText
          padding: 5%

  .contactPageButton .buttonContainer 
    font-size: 1rem
    
    
@media screen and (max-width: 25rem)
  .contactContainer
    .container
      .contactTitle
        font-size: 1.75rem
        text-align: center
      .contactForm
        padding-bottom: 1rem
        .contactText
          padding: 10%
        .contactInput input  
  
  .contactPageButton .buttonContainer 
    font-size: 1rem

@media screen and (max-width: 20rem)
  .contactContainer
    .container
      .contactTitle
        font-size: 1.5rem
        text-align: center
      .contactForm
        .contactText
          padding: 13%
          font-size: .75rem
        .contactInput input

  .contactPageButton .buttonContainer 
    font-size: 1rem
  