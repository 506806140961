.sportRule
    h3
        color: #00575D
    h4
        color: #e0451a
    h5
        color: #019494
    li::marker
            color: #fd0000
    b
        font-weight: bold
    a
        color: #cfbd16
