.orgContainerWrapper
    display: flex
    justify-content: center
    align-items: center
    height: 94vh
    width: 80rem
    margin: auto
    padding-bottom: 1rem
    .orgContainer
        .orgContainerFlexbox
            display: flex
            flex-wrap: wrap
            justify-content: center
            align-items: center
            gap: 1rem

        &::-webkit-scrollbar
            width: .25rem

        &::-webkit-scrollbar-track
            border-radius: .5rem
            background-color: transparent
            margin-top: 1rem
            margin-bottom: 1rem

        &::-webkit-scrollbar-thumb
            background: rgba(0, 88, 94, .85)
            border-radius: .5rem
            border-color: rgba(0, 88, 94, .85)

@media screen and (max-width: 81rem)
    .orgContainerWrapper
        background: rgba(0, 88, 94, 0.85)
        padding-top: .75rem
        padding-bottom: .75rem
        border-radius: 2rem
        -webkit-backdrop-filter: blur(.25rem)
        backdrop-filter: blur(.25rem)
        max-width: 28rem
        height: 78vh
        width: 95%
        margin: auto
        margin-top: 1.5rem
        box-shadow: 0rem .2rem .15rem 0rem rgba(0, 0, 0, 0.5)
        .orgContainer
            background: white
            padding: 1rem
            border-radius: 1.5rem
            width: 90%
            height: 100%
            overflow-y: scroll
            box-shadow: 0rem .2rem .15rem 0rem rgba(0, 0, 0, 0.5)

