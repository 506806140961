$font-size: 1rem

hr
    color: white !important

.lot-panel
    height: 100%
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: column
    padding-left: 1.5rem
    
    h3
        font-family: 'Montserrat', sans-serif
        font-weight: 500
        color: #FFFFFF
        font-size: 1.3em
        margin: 0
    
    h4
        padding-left: 15px
        margin: 0
        color: #FFFFFF
        font-size: 1rem

    .createLotButtonWrapper
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        .button
            min-width: 50%
            background: #009494
            color: #FFFFFF
            border: none
            border-radius: 9.25rem
            box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.3)
            font-family: 'Montserrat', sans-serif
            font-style: normal
            font-weight: 500
            font-size: $font-size
            padding: $font-size*0.5
            padding-left: 2.5rem
            padding-right: 2.5rem
            cursor: pointer
            text-transform: capitalize
            &:hover
                box-shadow: 0 0 30px rgba(0, 0, 0, 0.5)

    .h100
        height: 100%

    .lot-container
        display: flex
        flex-direction: column
        justify-content: center
        flex: 1 1
        margin-bottom: 1rem

        .leftWrapper
            display: flex
            flex-direction: column
            height: 100%
        
        .leave
            margin-top: 0%

        .blue
            background: #009494


        .buttonContainer
            color: #FFFFFF
            box-shadow: 0px 4px 4px 0px #00000040
            border: none
            padding-top: .3rem
            padding-bottom: .3rem
            padding-left: 3.25rem
            padding-right: 3.25rem
            font-size: 20px
            margin-bottom: 0rem !important


        .space-around
            display: flex
            flex-direction: column
            justify-content: space-between

            .scrollable
                overflow: scroll
                -webkit-mask-image: linear-gradient(black, 95%, transparent)
                mask-image: linear-gradient(black, 95%, transparent)
                &::-webkit-scrollbar
                    display: none

        .space-around-row
            display: flex
            flex-direction: row
            justify-content: space-around

        .lot-fields
            border: 1px solid #FFFFFF
            border-radius: 10px
            margin: 10px 10px 25px 10px
            padding: 6px 20px

        .heading
            margin-top: 40px
            font-size: 60px
            line-height: 89px

        .input-row
            width: 100% !important

        .user-row
            display: flex
            align-content: center
            justify-content: space-between
            border: 2px solid #41d6ac
            padding: 5px
            
            button
                margin: 0 20px

        .create-container
            display: flex
            flex-direction: column
            justify-content: center
            flex: 1 1
            margin-bottom: 3rem
        .join-container
            align-self: baseline
            margin-bottom: 5rem
            .req-container
                border-radius: 20px
                margin: 8px 0
                padding: 15px
                border: 1px solid #FFFFFF
                max-width: 400px
                word-wrap: break-word
                h4
                    color: #FFFFFF

                .req-fields
                    display: flex
                    border-radius: 10px
                    margin: 10px
                    padding: 6px 10px
                    max-height: 350px
                    overflow: auto
                    &::-webkit-scrollbar
                        width: 0px
                .push-right
                    display: flex
                    flex-direction: row
                    justify-content: flex-end
        .grid-container
            position: relative
            display: grid
            grid-template-columns: 4fr 1fr

        .grid-item
            position: relative
            z-index: 1
            display: flex
            justify-content: center
            flex-direction: column

            button
                padding: 0

.button-join
    background: #009494
    color: #FFFFFF
    border: none
    border-radius: 149px
    font-size: 18px
    padding: 5px 20px
    margin-left: 15px
    margin-top: 10px
    cursor: pointer
    &:hover
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1)

.buttons-row
    display: flex
    align-content: center
    justify-content: space-around

.selected-button
    background: #FFFFFF
    border: 0px
    color: #009494
    box-shadow: 0px 0px 20px 0px #00000040
        
.button-delete-user
    background: none
    margin: auto
    padding-bottom: 10px
    color: #FFFFFF
    border: 0

.join-input
    width: 100%

@media (max-width: 1300px)
    .lot-container
        padding: 2rem .25rem 0rem
    .buttons-row
        margin-top: 50px !important

@media (max-width: 600px)
    .buttons-row
        display: flex
        flex-direction: column
        align-content: center
        justify-content: center
        button 
            width: 80%
            margin: 10px auto 0
    h3 
        font-size: 1.2em !important
    
    h4
        font-size: 1em !important

