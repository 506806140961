$background-zoom: "./images/backgrounds/background-zoom.svg"
$background-zoom-mobile: "./images/backgrounds/background-zoom-mobile.svg"
$background-lines: "./images/backgrounds/background-lines.svg"
$background-hollow: "./images/backgrounds/background-hollow.svg"
$background-blobs: "./images/backgrounds/background-blobs.svg"

#root
    min-height: 100vh
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    display: grid

@mixin background-image($url)
    background-image: url($url)
    @media screen and (max-width: 59rem)
        background-image: url($background-zoom-mobile)

@mixin root-background($container-class, $background)
    #root:has(#{$container-class})
        @include background-image($background)

@include root-background('.brackets', $background-zoom)
@include root-background('.groups', $background-zoom)
@include root-background('.contactContainer', $background-lines)
@include root-background('.faqContainer', $background-hollow)
@include root-background('.games', $background-zoom)
@include root-background('.registerContainer', $background-lines)
@include root-background('.livescore', $background-lines)
@include root-background('.orgContainerWrapper', $background-blobs)
@include root-background('.partners', $background-lines)
@include root-background('.center-items', $background-hollow)
@include root-background('.rulesContainer', $background-blobs)
@include root-background('.scoreboard', $background-zoom)
@include root-background('.facultyStats', $background-zoom)
