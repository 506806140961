.alert-color
  color: #DC143C
  font-family: 'Montserrat', sans-serif

.registerContainer
  display: flex
  justify-content: center
  align-items: center
  padding-bottom: 5%
  .container
    display: grid
    grid-template-columns: 0.8fr 1.2fr
    background-size: cover
    width: 80%
    background: #00585E
    border-radius: 2rem
    margin-top: 2rem
    padding: .75rem .75rem
    .left
      height: 100%
      padding-bottom: 1rem
    .right
      height: 100%
      background: #FFFFFF
      box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25)
      border-radius: 2rem
      position: relative
      padding-bottom: 1rem
      padding-top: 1rem
      .register-form
        display: flex
        flex-direction: column
        align-items: center
        height: 100%
        .faculty-select
          border: none
          box-sizing: border-box
          border-radius: .75rem
          height: 3rem
          box-shadow: 0 0 1.25rem rgb(0 0 0 / 25%)
          color: #FFF
          background: #00585E
          padding: 0.5rem 1.5rem 0.5rem 4rem
        .registerformBody
          .textReg
            color: #00585E
            font-family: 'Montserrat', sans-serif
            font-weight: 600
          .form-select
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.75)
          flex: 1 1
          display: flex
          flex-direction: column
          align-items: center
          gap: 0.5rem
          padding-top: 1rem
          .checkbox
            margin-top: 1rem
            margin-bottom: 1rem
            .terms
                margin-left: .5rem
                font-family: 'Montserrat', sans-serif
                font-weight: 500
        button
          flex: 0 0
      .inputContainer
        width: 50%
        margin: 0
      .arrow
        position: absolute
        top: 50%
        left: 0
        transform: translate(-50%, -50%)
        width: 6.25rem
        height: 6.25rem

.fa-person
  left: 1.66rem !important

.registerContainerMarginTop
    margin-top: 5rem
.registerContainerRoleContainer // Role choice container
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 2rem
.registerContainerRoleBtn // Used importants in order to overwite any conflict with 'Button.sass' styles
    text-transform: none !important
    min-width: 13rem !important
    max-height: 5rem !important
    font-size: 1.5rem !important
    font-weight: 500 !important
    line-height: 1.6rem !important

@media screen and (max-width: 68.75rem)
    .arrow
        width: 4.5rem
        height: 4.5rem
    .container .left
        margin-bottom: 15%
    .registerContainerRoleContainer // Role choice container
        gap: 1.5rem
    .registerContainerRoleBtn // Used importants in order to overwite any conflict with 'Button.sass' styles
        min-width: 10rem !important
        max-height: 4rem !important
        font-size: 1rem !important
        line-height: 1.2rem !important

@media screen and (max-width: 59rem)
  .registerContainer
    height: unset
    padding-bottom: 10%
    .container
      background: rgba(0, 88, 94, .85)
      -webkit-backdrop-filter: blur(.25rem)
      backdrop-filter: blur(.25rem)
      display: block
      box-shadow: 0rem .5rem 1rem rgba(0, 0, 0, .5)
      .right
        margin: 1rem
        .register-form
            .registerformBody
                padding-top: 0rem
                .checkbox
                    display: flex
                    margin-bottom: 0rem
                    margin-top: 2rem
        form
          padding: 1rem 1rem 1rem 1rem
          gap: 1.25rem
        .inputContainer
          width: 100%
        .arrow
          top: 0
          left: 50%
          width: 3rem
          height: 3rem
  .registerContainerMarginTop
    margin-top: 0rem

@media screen and (max-width: 38rem)
    .registerContainer
        .container
            .right
                margin: .25rem

    .registerContainerRoleContainer // Role choice container
        gap: .5rem
    .registerContainerRoleBtn // Used importants in order to overwite any conflict with 'Button.sass' styles
        min-width: 7rem !important
        max-height: 3rem !important
        font-size: .6rem !important
        line-height: .8rem !important
