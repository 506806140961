body:has(.faqContainer)
    .modal
        .modal-content
            min-height: 35rem
            .modal-header
                .modal-title
                    font-size: 2rem
                    text-align: center
                    margin-left: 5rem
                    margin-right: 5rem
            .modal-body
                .modalText
                    font-size: 1.25rem
        @media screen and (max-width: 91rem)
            .modal-content
                min-height: 30rem
                .modal-header
                    .modal-title
                        font-size: 1.5rem
                        margin-left: 3rem
                        margin-right: 3rem
                .modal-body
                    .modalText
        @media screen and (max-width: 59rem)
            .modal-content
                min-height: 25rem
                .modal-header
                    .modal-title
                        font-size: 1.15rem
                        margin-left: 2rem
                        margin-right: 2rem
                .modal-body
                    .modalText
                        font-size: 1rem
        @media screen and (max-width: 49rem)
            .modal-content
                min-height: 30rem
                max-width: 26rem
                .modal-header
                    .modal-title
                        font-size: .75rem
                        margin-left: 1rem
                        margin-right: 1rem
                .modal-body
                    .modalText
                        font-size: .75rem
            

.faqContainer
    min-height: 90vh
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 2rem
    .faqBackgroundBorder
        background: white
        border-radius: 4rem
        padding-left: 1rem
        padding-right: 1rem
        padding-top: .5rem
        padding-bottom: .5rem
        .faqBackground
            background-image: url("../../images/backgrounds/background-faq.svg")
            background-repeat: no-repeat
            background-size: cover
            border-radius: 4rem
            padding-top: 3rem
            padding-bottom: 3rem
            padding-left: 6rem
            padding-right: 6rem
            position: relative
            .faqContainerFadeEffect
                position: relative
                .faqContainerMobile
                    justify-content: center
                    align-items: center
                    display: grid
                    grid-auto-flow: row
                    grid-template: repeat(1, 1fr) / repeat(5, 1fr)
                    gap: 2rem
                    overflow-y: scroll
                    max-height: 65vh
                    &::-webkit-scrollbar
                        display: none
                .cardContainer:nth-child(odd)
                    max-height: 95%
                /* Add fading effect to the top of the container */
                &::before
                    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(77, 158, 152, 1) 125%)
                    content: ''
                    position: absolute
                    top: 0
                    width: 100%
                    height: 1rem
                /* Add fading effect to the bottom of the container */
                &::after
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(77, 158, 152, 1) 125%)
                    content: ''
                    position: absolute
                    bottom: 0
                    width: 100%
                    height: 1rem

@media screen and (max-width: 97rem)
    .faqContainer
        .faqBackgroundBorder
            .faqBackground
                .faqContainerFadeEffect
                    .faqContainerMobile
                        grid-template: repeat(1, 1fr) / repeat(4, 1fr)

@media screen and (max-width: 72rem)
    .faqContainer
        .faqBackgroundBorder
            .faqBackground
                .faqContainerFadeEffect
                    .faqContainerMobile
                        grid-template: repeat(1, 1fr) / repeat(3, 1fr)

@media screen and (max-width: 60rem)
    .faqContainer
        .faqBackgroundBorder
            .faqBackground
                .faqContainerFadeEffect
                    .faqContainerMobile
                        grid-template: repeat(1, 1fr) / repeat(2, 1fr)

@media screen and (max-width: 59rem)
    .faqContainer
        min-height: 85vh
        display: flex
        flex-direction: column

        .faqBackgroundBorder
            background: none
            .faqBackground
                background: none
                padding: 0rem
                .faqContainerFadeEffect
                    .faqContainerMobile
                        grid-template: none
                        background: rgba(0, 88, 94, .85)
                        -webkit-backdrop-filter: blur(.25rem)
                        backdrop-filter: blur(.25rem)
                        padding: 2rem 1.75rem
                        border-radius: 1.5rem
                        overflow-y: hidden
                        .cardContainer:nth-child(odd)
                            max-height: 100%
                        .faqContainerFadeEffectMobile
                            position: relative
                            .faqCardsWrapperMobile
                                display: flex
                                flex-direction: column
                                gap: 1rem
                                overflow-y: scroll
                                max-height: 56vh
                                .faqCardBtnMobile
                                    display: flex
                                    background: white
                                    width: 15rem
                                    min-height: 4.5rem
                                    padding-left: 1rem
                                    padding-right: 1rem
                                    padding-top: .5rem
                                    padding-bottom: .5rem
                                    border: none
                                    border-radius: 1rem
                                    box-shadow: .25rem .25rem 0px 0px rgba(0, 0, 0, 0.25)
                                    overflow-wrap: break-word
                                    color: rgba(0, 88, 94, 1)
                                    font-size: .75rem
                                    text-align: left
                                    align-items: center
                                &::-webkit-scrollbar
                                    display: none
                            /* Add fading effect to the top of the container */
                            &::before
                                background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(36, 111, 117, 1) 125%)
                                content: ''
                                position: absolute
                                top: 0
                                width: 100%
                                height: 1rem
                            /* Add fading effect to the bottom of the container */
                            &::after
                                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(36, 111, 117, .75) 125%)
                                content: ''
                                position: absolute
                                bottom: 0
                                width: 100%
                                height: 1rem
                    /* Remove fading effect from the top of the container */
                    &::before
                        display: none
                    /* Remove fading effect from the bottom of the container */
                    &::after
                        display: none
        .faqSwitchBarMobile
            max-height: 4rem
            padding-left: 8rem
            padding-right: 8rem
            margin-bottom: -1.5rem
            background: rgba(0, 148, 148, 1)
            border-radius: 1.25rem
            display: flex
            align-items: center
            justify-content: center
            z-index: 1
            .faqSwitchBarImg
                max-width: 6rem
