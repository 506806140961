#navbar
    background: transparent

.navbar
    background: transparent
    display: flex
    max-height: 7rem
    z-index: 10
    visibility: visible
    position: relative

#hamburger
    border: 0rem
    margin-left: 1rem
    margin-top: 1.25rem

#offcanvasNavbar
    margin-top: .5rem
    margin-left: .5rem
    background: transparent
    height: 28.75rem
    width: 15.5rem
    border: .25rem solid #009494
    background-size: "cover"
    background-repeat: "no-repeat"
    background-position: left center
    background-color: white
    border-radius: 2.5rem
    overflow: hidden

#navbar-container > div > a
    color: #009494

#navbar-container > div > a:hover
    color: #FF5729
    font-weight: bold

#navbar-container > div > a.active
    background-color: unset !important
    color: #FF5729 !important
    font-weight: bold

@keyframes rollout
    0%
        transform: translateY(25px)
    100%
        transform: none

.roll-out
    animation: rollout 0.5s

div.dropdown-menu.show
    position: relative
    border-radius: 2rem
    margin-left: 1.25rem

#navbar-container
    margin-top: .5rem
    padding-left: 0
    border-radius: 2rem
    display: flex
    align-items: baseline

.nav-link
    margin-left: 1rem
    font-family: 'Montserrat', sans-serif
    font-size: 1rem
    font-weight: 500
    display: flex
    gap: .25rem

.logo-nav
    visibility: hidden
    width: 1rem
.nav-link:hover > .logo-nav
    visibility: visible 

.sports-link
    color: #FF5729 !important
    font-weight: 600

#side-navbar
    border-radius: 1.25rem

.logo
    position: absolute
    right: 1rem
    top: 1rem
    max-width: 10vw

@media screen and ( max-width:  82rem)
    .navbar
        width: 6rem
    .logo
        visibility: hidden

@media screen and (max-width: 59rem)
    #hamburger
        margin-top: 1.25rem
        margin-left: .25rem
