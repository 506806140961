.livescore
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    min-height: 100vh
    .livescore-container
        border: 1px solid #45444566
        box-shadow: 0px .25rem .25rem 0px #00000040
        background-color: white
        border-radius: 4rem
        padding-top: 2.25rem
        padding-bottom: 2.25rem
        padding-left: 1rem
        padding-right: 1rem
        width: 90%
        position: relative
        .livescoreContainerFadeEffect
            position: relative
            .livescoreScrollable
                overflow-y: scroll
                height: 55vh

                &::-webkit-scrollbar
                    width: .25rem

                &::-webkit-scrollbar-track
                    border-radius: .5rem
                    background-color: transparent
                    margin-top: 1rem
                    margin-bottom: 1rem

                &::-webkit-scrollbar-thumb
                    background: rgba(0, 88, 94, .85)
                    border-radius: .5rem
                    border-color: rgba(0, 88, 94, .85)

            /* Add fading effect to the top of the container */
            &::before
                background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #ffffff 150%)
                content: ''
                position: absolute
                top: 0
                width: 100%
                height: .75rem
                z-index: 1
            /* Add fading effect to the bottom of the container */
            &::after
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 150%)
                content: ''
                position: absolute
                bottom: 0
                width: 100%
                height: .75rem

    .row
        margin-left: 0
        margin-right: 0
    .placeholder-live
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        text-align: center
        font-size: 3.5rem
        font-weight: 700
        padding: 3.5rem
        width: 80%
        margin: 0 auto
        color: #00585E

    @media (max-width: 81rem)
        .livescore-container
            margin-bottom: 3rem

    @media (max-width: 59rem)
        .livescore-container
            padding-top: 1.75rem
            padding-bottom: 1.75rem
            padding-left: .25rem
            padding-right: .25rem
            border-radius: 3rem
            .livescoreContainerFadeEffect
                .livescoreScrollable
                    max-height: 60vh
                    padding-right: .25rem

        .scrollable
            min-height: 65vh

        .placeholder-live
            padding: 1rem
            font-size: 1.75rem
