.cardContainer
    display: flex
    background: white
    flex-direction: column
    align-items: center
    width: 13rem
    box-shadow: .25rem .25rem 0px 0px rgba(0, 0, 0, 0.25)
    .cardTop
        display: flex
        align-items: center
        flex-direction: column
        min-height: 18rem
        .cardImg
            margin-top: .5rem
            max-width: 9rem
            padding: 1rem
        .cardText
            text-align: center
            margin-top: 1rem
            padding-left: .5rem
            padding-right: .5rem
            font-family: 'Montserrat', sans-serif
            font-weight: 500
    .cardBottom
        display: flex
        align-items: center
        justify-content: end
        flex-direction: column
        padding-bottom: 2rem
        .cardBtn

