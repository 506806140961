@import url('https://fonts.googleapis.com/css2?family=Bigelow+Rules&family=Open+Sans:ital,wght@1,300&display=swap')

.landing-text
    font-family: 'Montserrat', sans-serif
    font-size: 4rem
    font-weight: 500
    color: #454445
    margin-top: 2rem
    text-align: center
    @media screen and (max-width: 39.5rem)
        font-size: 2.5rem
        margin-top: 0rem

.landing-banner
    z-index: 0
    width: 100vw
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    position: relative
    background-image: url("../../images/backgrounds/background-landing.svg")
    background-repeat: no-repeat
    background-size: cover
    background-position: center

    img
        max-width: 25rem
        height: auto
    .landing-text
        margin: 0rem
        margin-top: -2rem



@media screen and (max-width: 40rem)
    .landing-banner
        background-image: url("../../images/backgrounds/background-landing-mobile.svg")
        img
            max-width: 17.5rem
        .landing-text
            margin-bottom: -1.75rem
