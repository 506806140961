.scoreboard
    margin-top: 2rem
    margin-left: 5rem
    margin-right: 5rem
    
    .headerContainer
        display: flex
        flex-direction: row
        justify-content: space-between
        width: 100%
        padding: 2rem 7% 1.25rem 5%
        span
            color: #FFFFFF
            font-size: 30pt
    .scoreboard-row
        border-radius: 2rem
        background: #00585E
        box-shadow: 0rem .25rem .5rem 0rem rgba(0, 0, 0, 0.25)

        .scoreboard-box
            padding: 0

            .faculty-row:last-child
                border-radius: 0 0 2rem 2rem

        .scoreboard-header
            padding: 1.25rem 5% 0

            .faculty-name
                font-size: 2.25rem
                color: white
                text-align: left
                margin-bottom: 0!important

            .faculty-total
                .total
                    font-size: 2.25rem
                    color: white
                    text-align: left
                    padding-right: 1rem

    .scoreboard-scrollable
        overflow-y: scroll
        max-height: 40rem

        &::-webkit-scrollbar
            width: .25rem

        &::-webkit-scrollbar-track
            border-radius: .5rem
            background-color: transparent
            margin-top: 2rem
            margin-bottom: 2rem

        &::-webkit-scrollbar-thumb
            background: rgba(255, 255, 255, 0.85)
            border-radius: .5rem
            border-color: rgba(0, 88, 94, .85)


@media (max-width: 59rem)
    .scoreboard
        margin-top: 1rem
        margin-left: 1.25rem
        margin-right: 1.25rem
        .scoreboard-row
            border-radius: 1.25rem
            background: #00585E
            

            .scoreboard-box
                .faculty-row:last-child
                    border-radius: 0 0 1.25rem 1.25rem

            .scoreboard-header
                .faculty-name
                    p
                        font-size: 1.75
                        margin-bottom: 0

                .faculty-total
                    .total
                        font-size: 1.75
                        margin-bottom: 0
