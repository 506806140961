body:has(.games)
    .logo
        display: none

.games
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: 100vh
    padding-bottom: 2rem

    .gamesContainer
        background-color: white
        width: 90%
        border-radius: 2rem
        box-shadow: 0rem 0rem 1.25rem 0rem rgba(0, 0, 0, 0.3)
        position: relative

        .gamesContainerNav
            display: flex
            flex-direction: row
            justify-content: flex-start
            align-items: center
            background-color: rgba(255, 87, 41, 1)
            border-top-left-radius: 2rem
            border-top-right-radius: 2rem
            padding-top: .5rem
            padding-bottom: .5rem
            gap: 5rem

            .gamesContainerNavSport
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                text-align: center
                font-family: 'Montserrat', sans-serif
                font-weight: 700
                font-size: 2.5rem
                color: white
                text-transform: uppercase
                margin-left: 7.5rem

            .gamesContainerNavButtons
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 3rem
                .gamesBtnSizing
                    min-width: 9.5rem
                    height: 2.75rem

        .gamesList
            height: 50vh
            width: 97%
            margin: 1.25rem auto
            padding-top: .5rem
            padding-bottom: .5rem
            background-image: url("../../images/backgrounds/background-faq.svg")
            background-repeat: no-repeat
            background-size: cover
            border-radius: 3rem
            overflow-y: scroll
            &::-webkit-scrollbar
                width: .25rem

            &::-webkit-scrollbar-track
                border-radius: .5rem
                background-color: transparent
                margin-top: 2.5rem
                margin-bottom: 2.5rem

            &::-webkit-scrollbar-thumb
                background: rgba(0, 88, 94, .85)
                border-radius: .5rem
                border-color: rgba(0, 88, 94, .85)

@media screen and (max-width: 78rem)
    .games
        .gamesContainer
            .gamesContainerNav
                gap: 2.5rem
                .gamesContainerNavSport
                    font-size: 2.25rem
                    margin-left: 3.5rem
                .gamesContainerNavButtons
                    gap: 1.5rem
                    .gamesBtnSizing
                        min-width: 9rem
                        height: 2.5rem
            .gamesList
                height: 50vh
                width: 95%

@media screen and (max-width: 59rem)
    .games
        .gamesContainer
            background: rgba(0, 88, 94, 0.85)
            -webkit-backdrop-filter: blur(.25rem)
            backdrop-filter: blur(.25rem)
            height: 40rem
            margin-bottom: 1rem

            .gamesContainerNav
                flex-direction: column
                padding-top: .25rem
                padding-bottom: .5rem
                gap: 0rem
                .gamesContainerNavSport
                    font-size: 2rem
                    margin-left: 0rem
                .gamesContainerNavButtons
                    width: 100%
                    gap: 2.5rem
                    .gamesBtnSizing
                        min-width: 8rem
                        height: 2.1rem
                        font-size: 1rem
                        padding-left: 1rem
                        padding-right: 1rem
                        padding-top: .3rem
                        padding-bottom: .3rem
            .gamesList
                background: white
                height: 82%
                width: 93%
                margin-top: 0rem
                border-top-left-radius: 0rem
                border-top-right-radius: 0rem
                border-bottom-left-radius: 1.5rem
                border-bottom-right-radius: 1.5rem

                &::-webkit-scrollbar-track
                    margin-top: .1rem
                    margin-bottom: 1.5rem

@media screen and (max-width: 45rem)
    .games
        .gamesContainer
            .gamesContainerNav
                .gamesContainerNavSport
                    font-size: 1.75rem
                .gamesContainerNavButtons
                    gap: 1.5rem
                    .gamesBtnSizing
                        min-width: 7rem
                        height: 1.8rem
                        font-size: .9rem
                        padding-left: 1rem
                        padding-right: 1rem
                        padding-top: .25rem
                        padding-bottom: .25rem
            .gamesList
                height: 84%
                width: 92%

@media screen and (max-width: 32rem)
    .games
        .gamesContainer
            height: 35rem
            .gamesContainerNav
                .gamesContainerNavSport
                    font-size: 1.5rem
                .gamesContainerNavButtons
                    gap: .75rem
                    .gamesBtnSizing
                        min-width: 6rem
                        height: 1.75rem
                        font-size: .8rem
                        padding-left: .75rem
                        padding-right: .75rem
            .gamesList
                height: 83%
                width: 90%
