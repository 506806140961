$border-radius: 2rem

.display-none
  display: none

.alert-color
  color: #DC143C
  font-family: 'Montserrat', sans-serif

.buttons-row
  width: 70%
  display: flex
  align-content: center
  justify-content: space-around
  margin: 0 auto 3rem !important
  margin-bottom: 0rem
  .center-button
    display: flex

  .button-lot
    text-align: center
    background: none
    border-radius: 9.25rem
    border: 2px solid #FFFFFF
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    color: #FFFFFF
    background-color: #009494
    padding: .25rem 1.5rem
    font-size: 1.5em
.active
    background-color: white !important
    color: #00585E !important

.center-items
  padding-bottom: 2rem
  align-items: center
  display: flex
  flex-direction: column

.profileContainer
  display: flex
  justify-content: center
  align-items: center

  .container
    display: grid
    grid-template-columns: 0.8fr 1.2fr
    background-size: cover
    gap: 3rem
    width: 80%
    margin: 0 auto 5 rem
    background: #00585E
    border-radius: $border-radius
    padding: .75rem .75rem
    .left
      height: 100%
    .right
      display: flex
      justify-content: space-around
      flex-direction: column
      height: 100%
      background: #FFFFFF
      border-radius: $border-radius
      box-shadow: 0rem .3rem .25rem 0rem rgb(0, 0, 0, .25)
      padding: 0 1.25rem 0  
      position: relative
      font-family: 'Montserrat', sans-serif
      font-weight: 500

      .input-row
        div
          display: flex
          align-content: center
          justify-content: center
        .inputContainer
          .inputBoxProfile
          input
            font-family: 'Montserrat', sans-serif
            font-weight: 500
            background-color: #00585E
            color: white
            box-shadow: 0 .5rem .5rem rgba(0, 0, 0, .25)
          &:after
            color: white
          .icon
            color: white

      .text-profile
        font-family: 'Montserrat', sans-serif
        font-weight: 500
        text-align: center
        margin-bottom: 2rem
        margin-top: 1rem

      .button-div
        display: flex
        justify-content: center
        margin-top: 2rem

        .buttonContainer
          background-color: #009494
          box-shadow: 0rem .25rem .25rem 0rem #00000040
          color: white
          font-family: 'Montserrat', sans-serif
          font-weight: 500
          font-size: 1rem
          padding-left: 3.75rem
          padding-right: 3.75rem
          margin-bottom: 1rem


      .inputContainer
          width: 70%
          .input
            padding: 2px

.singlePageContainerOverwrite
    display: flex !important
.singlePageRightOverwrite
    box-shadow: none !important

.profileContainerSingle
  width: 50vw !important
  .container
    border: 2px solid #009494 !important
    border-radius: 1.25rem !important
    background: #009392D9 !important
    box-shadow: 0rem .25rem .25rem 0rem #00000040 !important
    .right
      width: 100%
      position: relative
      color: white
      background-color: transparent !important

      .text-profile
        text-align: center
        margin-bottom: 2rem
        font-family: 'Montserrat', sans-serif
        font-weight: 500
      
      .input-row
        div
          font-size: 1.25rem

      .button-div
        .buttonContainer
          background-color: white !important
          color: #00585E !important


.alert-pass
  display: block !important

.mobile
  display: none!important

@media screen and (max-width: 81.25rem)
  .profileContainerSingle
    width: 70vw !important
    margin-top: 2rem


@media screen and (max-width: 63rem)
  .profileContainer .container
    .right
        box-shadow: none
        .inputContainer
            width: 70%


@media screen and (max-width: 59rem)
  .profileContainerSingle
    width: 90vw !important
    .container
      background: #00585E !important
      border: none !important
    .removeMargin
        margin-bottom: 0rem !important

  .profileContainer
    flex-direction: column
    height: auto
    .container
      flex-direction: column
      height: auto
      width: 80%
      padding-bottom: .75rem
      margin-bottom: 4.25rem
      .left
        width: 100%
        height: auto
      .right
        width: 92%

        .lot-panel
            padding-left: 0rem

        .text-profile
            text-align: center

        .button-div
            display: flex
            justify-content: center
        form
          padding: 1.5rem .75rem .75rem
          gap: 1.25rem
        .inputContainer
          width: 90%
  .buttons-row
    display: none
  .desktop
    display: none!important
  .mobile
    display: flex!important
    .right
      background: #00585E!important
      .bordered
        border: 2px solid rgba(255, 255, 255, 0.85)
        border-radius: .75rem
      .heading
        color: white!important
      .titleProfileMobile
        margin-bottom: 1rem
        padding-top: 1rem
      .mobile-buttons
        display: flex
        justify-content: space-around
        flex-direction: row
        flex-wrap: wrap
        .center
          display: flex
          justify-content: center
          padding: 0 2px
        .button-lot-mobile
          border-radius: 2rem
          min-width: 6.25rem
          font-weight: 400
          line-height: 1rem
          box-shadow: 0rem .25rem .25rem rgba(0, 0, 0, 0.25)
      .back-and-title
        display: flex
        justify-content: center
        align-items: center
        .mobile-title
          color: white
          font-weight: bold
          font-size: 2rem
        .arrow-container
          position: absolute
          cursor: pointer
          left: 1rem
          .arrow
            width: 2.75rem!important
      


@media screen and (max-width: 37.5rem)
  .myLot
    margin-bottom: 10rem
  .profileContainer
    padding-bottom: 1rem
    .container
      width: 95%
      padding: 1rem
      .left
        .inputs
          width: 60%
        a
          transform: translateX(-5px)
      .right
        width: 100%
        padding: 0
        .text-profile
          margin-bottom: .5rem
  .mobile
    padding: 0
    .lot-container
      width: 100%
      margin: 0
      padding: 0 !important
    .lot-fields
      width: 90%
      padding: .75rem !important
    .mobile-title
      font-size: 1.5rem!important
    .arrow
      height: 2rem!important
