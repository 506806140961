#root:has(.facultyStats)
    display: block // This keeps the whole container not to overflow in the #body element in width

.facultyStats
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: 91vh
    padding-bottom: 2rem

    .facultyStatsContainer
        background-color: white
        height: 45rem
        width: 75%
        border-radius: 2rem
        box-shadow: 0rem 0rem 1.25rem 0rem rgba(0, 0, 0, 0.3)

        .facultyStatsContainerNav
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            background-color: rgba(255, 87, 41, 1)
            border-top-left-radius: 2rem
            border-top-right-radius: 2rem
            padding-top: 1rem
            padding-bottom: 1rem

            .facultyStatsContainerNavFaculty
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 20%
                gap: .5rem

                .facultyStatsContainerNavFacultyImg
                    background: rgba(242, 238, 226, 0.9)
                    border-radius: 15rem
                    max-width: 3.75rem
                    max-height: 3.75rem
                    padding: .25rem
                    margin-right: -.5rem

                .facultyStatsContainerNavFacultyDropdown
                    .dropdown-toggle
                        background: none
                        border: none
                        font-family: 'Montserrat', sans-serif
                        font-weight: 600
                        font-size: 2rem
                    .dropdown-menu
                        overflow: hidden
                    
                .fitiar-transportul-si-chimia-de-ras .dropdown-toggle
                        font-size: 1rem !important

            .facultyStatsContainerNavButtons
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 55%
                gap: 3.5rem

                .facultyStatsNavBtn
                    text-transform: none
                    min-width: 10.5rem
                    font-size: 1.25rem
                    padding-left: .5rem
                    padding-right: .5rem
                    padding-top: .5rem
                    padding-bottom: .5rem

            .facultyStatsContainerNavStats
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                font-family: 'Montserrat', sans-serif
                width: 25%
                color: white
                font-weight: 600
                font-size: 1.25rem
                line-height: 1.5rem
                gap: 2rem

                .facultyStatsContainerNavStatsRank
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center

                .facultyStatsContainerNavStatsPoints
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center

        .facultyStatsContainerContent
            height: 82%
            width: 97%
            margin: 1.25rem auto
            padding: 1.5rem
            background-image: url("../../images/backgrounds/background-faq.svg")
            background-repeat: no-repeat
            background-size: cover
            border-radius: 3rem
            overflow-y: auto

            &::-webkit-scrollbar
                width: .25rem

            &::-webkit-scrollbar-track
                border-radius: .5rem
                margin-bottom: 1rem

            &::-webkit-scrollbar-thumb
                background: rgba(0, 88, 94, .85)
                border-radius: .5rem
                border-color: rgba(0, 88, 94, .85)

            .facultyStatsContainerContentPlayers
                background: white
                display: grid
                grid-auto-flow: row
                grid-template-rows: repeat(5, 1fr)
                grid-template-columns: repeat(4, 1fr)
                border-radius: 1.5rem
                padding: 1.5rem
                min-height: 100%

@media screen and (max-width: 88rem)
    .facultyStats .facultyStatsContainer
        width: 90%

@media screen and (max-width: 78rem)
    .facultyStats
        .facultyStatsContainer
            height: 46rem
            .facultyStatsContainerNav
                padding-top: .8rem
                padding-bottom: .8rem
                .facultyStatsContainerNavFaculty
                    gap: 0rem
                    .facultyStatsContainerNavFacultyImg
                        max-width: 3.25rem
                        max-height: 3.25rem
                        padding: .2rem
                    .facultyStatsContainerNavFacultyDropdown .dropdown-toggle
                        font-size: 1.75rem
                    .fitiar-transportul-si-chimia-de-ras .dropdown-toggle
                        font-size: .85rem !important
                .facultyStatsContainerNavButtons
                    gap: 1.5rem
                    .facultyStatsNavBtn
                        min-width: 9.25rem
                        font-size: 1.2rem
                        padding-left: .25rem
                        padding-right: .25rem
                .facultyStatsContainerNavStats
                    font-size: 1.2rem
                    gap: 1rem
                    .facultyStatsContainerNavStatsRank
                    .facultyStatsContainerNavStatsPoints
            .facultyStatsContainerContent
                height: 84%
                width: 95%
                .facultyStatsContainerContentPlayers

@media screen and (max-width: 61rem)
    .facultyStats
        .facultyStatsContainer
            background: rgba(0, 88, 94, 0.85)
            -webkit-backdrop-filter: blur(.25rem)
            backdrop-filter: blur(.25rem)
            box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, 0.3)
            .facultyStatsContainerNav
                padding-top: .6rem
                padding-bottom: .6rem
                .facultyStatsContainerNavFaculty
                    flex-direction: column
                    width: 30%
                    .facultyStatsContainerNavFacultyImg
                        margin-right: 0rem
                    .facultyStatsContainerNavFacultyDropdown .dropdown-toggle
                        margin-top: -.25rem
                        margin-bottom: -.5rem
                        font-size: 1.3rem
                .facultyStatsContainerNavButtons
                    flex-direction: column
                    width: 40%
                    gap: .4rem
                    .facultyStatsNavBtn
                        min-width: 0rem
                        width: 9.5rem
                        font-size: 1rem
                        padding-left: 1rem
                        padding-right: 1rem
                        padding-top: .15rem
                        padding-bottom: .15rem
                .facultyStatsContainerNavStats
                    flex-direction: column
                    width: 30%
                    font-size: 1rem
                    line-height: 1.25rem
                    .facultyStatsContainerNavStatsRank
                    .facultyStatsContainerNavStatsPoints
            .facultyStatsContainerContent
                background: white
                box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, 0.4)
                height: 81%
                width: 93%
                margin-top: 0rem
                border-top-left-radius: 0rem
                border-top-right-radius: 0rem
                border-bottom-left-radius: 1.5rem
                border-bottom-right-radius: 1.5rem
                .facultyStatsContainerContentPlayers
                    padding: 0rem

@media screen and (max-width: 45rem)
    .facultyStats
        .facultyStatsContainer
            height: 42rem
            .facultyStatsContainerNav
                padding-top: .5rem
                padding-bottom: .5rem
                .facultyStatsContainerNavFaculty
                    .facultyStatsContainerNavFacultyImg
                        max-width: 3.25rem
                        max-height: 3.25rem
                        padding: .15rem
                    .facultyStatsContainerNavFacultyDropdown .dropdown-toggle
                        font-size: 1.2rem
                .facultyStatsContainerNavButtons
                    gap: .35rem
                    .facultyStatsNavBtn
                        width: 8.25rem
                        font-size: .9rem
                        padding-left: .75rem
                        padding-right: .75rem
                .facultyStatsContainerNavStats
                    font-size: 1rem
                    line-height: 1.15rem
                    gap: .75rem
                    .facultyStatsContainerNavStatsRank
                    .facultyStatsContainerNavStatsPoints
            .facultyStatsContainerContent
                height: 81%
                width: 92%
                padding: .75rem
                .facultyStatsContainerContentPlayers
                    grid-template-rows: repeat(6, 1fr)
                    grid-template-columns: repeat(3, 1fr)

@media screen and (max-width: 32rem)
    .facultyStats
        .facultyStatsContainer
            height: 38rem
            .facultyStatsContainerNav
                .facultyStatsContainerNavFaculty
                    .facultyStatsContainerNavFacultyImg
                        max-width: 2.75rem
                        max-height: 2.75rem
                        padding: .1rem
                    .facultyStatsContainerNavFacultyDropdown .dropdown-toggle
                        margin-top: -.5rem
                        margin-bottom: -.75rem
                        font-size: 1.1rem
                    .fitiar-transportul-si-chimia-de-ras .dropdown-toggle
                        font-size: .8rem !important
                .facultyStatsContainerNavButtons
                    gap: .25rem
                    .facultyStatsNavBtn
                        width: 7rem
                        font-size: .8rem
                        padding-left: .5rem
                        padding-right: .5rem
                        padding-top: .1rem
                        padding-bottom: .1rem
                .facultyStatsContainerNavStats
                    font-size: .9rem
                    line-height: 1rem
                    .facultyStatsContainerNavStatsRank
                    .facultyStatsContainerNavStatsPoints
            .facultyStatsContainerContent
                height: 82%
                width: 90%
                .facultyStatsContainerContentPlayers
                    grid-template-rows: repeat(8, 1fr)
                    grid-template-columns: repeat(2, 1fr)
