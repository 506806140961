.forgotContainer
  color: #FFFFFF
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column
  padding: 0rem 1rem 0rem 1rem
  .formBody
    width: 80%
    padding-top: 2rem
    display: flex
    flex-direction: column
    justify-content: center
    flex: 1 1
    h1
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        text-align: center
        margin-bottom: 2rem
        padding-top: 0
    .inputsForgot
        margin-bottom: 30%
        .inputForgot
            margin-bottom: 1rem
        .infoAlert
            margin-bottom: 1rem
  .forgotBtn
    padding-left: 3rem
    padding-right: 3rem
.alert-color-red
  color: red
.alert-color-green
  color: green

@media screen and (max-width: 59.5rem)
    .inputsForgot
        margin-bottom: 10% !important
