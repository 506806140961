.contactPersonContainer
    background: white
    display: flex
    justify-content: center
    align-items: center
    padding: .5rem
    border-radius: 1rem
    box-shadow: .1rem .1rem .25rem .1rem rgba(0, 0, 0, 0.15)
    .contactPersonRelations
        display: flex
        flex-direction: row
        align-items: center
        width: 25rem
        gap: 1rem
        .contactPersonInfo
            display: flex
            flex-direction: column
            gap: 1rem
            .contactPersonHeader
                display: flex
                flex-direction: column
                justify-content: center
                .contactPersonName
                .contactPersonRole

            .contactPersonFooter
                display: flex
                flex-direction: column
                justify-content: center
                .contactPersonPhoneRow
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: .5rem
                    .contactPersonPhone
                .contactPersonEmailRow
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: .5rem
                    .contactPersonEmail

    .contactPersonOrg
        display: flex
        flex-direction: column
        justify-content: start
        align-items: center
        width: 10rem
        height: 12.5rem
        gap: 1rem
        .contactPersonOrgDetailsRow
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            .contactPersonName
                text-align: center
            .contactPersonRole
                text-align: center
                line-height: 1rem

.contactPersonText
    font-size: 1rem
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    color: #454445

.contactPersonTextContact
    font-size: .85rem
    font-family: 'Montserrat', sans-serif
    font-weight: 400
    color: #454445

.contactPersonImg
    max-width: 8rem

.contactPersonIcon
    max-width: .9rem

@media screen and (max-width: 81rem)
    .contactPersonContainer
        .contactPersonRelations
            width: 23rem
            .contactPersonInfo
                .contactPersonHeader
                    .contactPersonName
                    .contactPersonRole
                .contactPersonFooter
                    .contactPersonPhoneRow
                        .contactPersonPhone
                    .contactPersonEmailRow
                        .contactPersonEmail
        .contactPersonOrg
            .contactPersonOrgDetailsRow
                .contactPersonName
                .contactPersonRole
    .contactPersonText
    .contactPersonTextContact
        font-size: .75rem
    .contactPersonImg
    .contactPersonIcon

@media screen and (max-width: 59rem)
    .contactPersonContainer
        background: rgba(0, 148, 148, 0.47)
        .contactPersonRelations
            width: 20rem
            .contactPersonInfo
                .contactPersonHeader
                    .contactPersonName
                        font-weight: 600
                    .contactPersonRole
                        color: rgba(0, 88, 94, 1) !important
        .contactPersonOrg
            width: 8.5rem
            .contactPersonOrgDetailsRow
                .contactPersonName
                    font-weight: 600
                .contactPersonRole
                    color: rgba(0, 88, 94, 1) !important
                    line-height: 1.5rem
    .contactPersonImg
        max-width: 7rem
    .contactPersonIcon
        max-width: .7rem
    .contactPersonTextContact
        font-size: .6rem
        font-weight: 500