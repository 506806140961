$font-size: 1rem
.buttonContainer
  width: auto
  background: #009494
  color: #FFFFFF
  border: none
  border-radius: 9.25rem
  box-shadow: 0rem .25rem .25rem rgba(0, 0, 0, 0.25)
  font-family: 'Montserrat', sans-serif
  font-style: normal
  font-weight: 500
  font-size: $font-size
  padding: $font-size*0.5 $font-size*1.8
  cursor: pointer
  text-transform: capitalize
  &:hover
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2)

.buttonDisabled
  cursor: not-allowed