.faculty-row
    width: 100%
    padding: 10px 5%
    background: white
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)

    &:nth-child(odd)  
        background: white
        .faculty-name
            color: #00585E
        .faculty-score
            background: #00585E
            color: white

    &:nth-child(even) 
        background: #00585E
        .faculty-name
            color: white
        .faculty-score
            background: white
            color: #00585E

    .faculty-name
        font-family: 'Montserrat', sans-serif
        font-style: normal
        font-weight: 500
        font-size: 30px
        line-height: 59px
     

    .faculty-score
        padding: 1px 80px
        border-radius: 30px
        font-family: 'Montserrat', sans-serif
        font-style: normal
        font-weight: 500
        font-size: 20px
        line-height: 40px
        color: #FFFFFF
        margin: 0


@media (max-width: 768px)
    .faculty-row
        .faculty-name, .faculty-score
            font-size: 18px

