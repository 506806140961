#root:has(.brackets)
    display: block

.brackets
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: 100vh
    padding-bottom: 2rem

    .bracketsContainer
        background-color: white
        height: 42.5rem
        border-radius: 2rem
        box-shadow: 0rem 0rem 1.25rem 0rem rgba(0, 0, 0, 0.3)

        .bracketsContainerNav
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            background-color: rgba(255, 87, 41, 1)
            border-top-left-radius: 2rem
            border-top-right-radius: 2rem
            padding: 1.25rem
            gap: 4rem

            .bracketsContainerNavButtons1
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 4rem

                .bracketsBtn1
                    min-width: 10rem
                    height: 3rem
                    font-size: 1.2rem
                    text-transform: none
                    padding-left: 2rem
                    padding-right: 2rem
                    padding-top: .5rem
                    padding-bottom: .5rem

            .bracketsContainerNavButtons2
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 4rem

                .bracketsBtn2
                    min-width: 10rem
                    height: 3rem
                    font-size: 1.2rem
                    text-transform: none
                    padding-left: .25rem
                    padding-right: .25rem
                    padding-top: .5rem
                    padding-bottom: .5rem

        .bracketsContainerContent
            height: 81%
            width: 97%
            margin: 1.25rem auto
            padding: 1rem
            background-image: url("../../images/backgrounds/background-faq.svg")
            background-repeat: no-repeat
            background-size: cover
            border-radius: 3rem

            &::-webkit-scrollbar
                width: .25rem

            &::-webkit-scrollbar-track
                border-radius: .5rem
                margin-bottom: 1rem

            &::-webkit-scrollbar-thumb
                background: rgba(0, 88, 94, .85)
                border-radius: .5rem
                border-color: rgba(0, 88, 94, .85)

        .bracketsContainerVolleyball
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            text-align: center
            color: rgba(255, 255, 255, 1)
            font-weight: 600
            padding: 5rem
            font-size: 1rem

@media screen and (max-width: 75rem)
    .brackets
        min-height: 78vh
        .bracketsContainer
            height: 37rem
            .bracketsContainerNav
                gap: 1.75rem
                .bracketsContainerNavButtons1
                    gap: 1.75rem
                    .bracketsBtn1
                        min-width: 9rem
                        height: 2.5rem
                        font-size: 1.1rem
                        padding-left: 1.75rem
                        padding-right: 1.75rem
                        padding-top: .25rem
                        padding-bottom: .25rem
                .bracketsContainerNavButtons2
                    gap: 1.75rem
                    .bracketsBtn2
                        min-width: 9rem
                        height: 2.5rem
                        font-size: 1.1rem
                        padding-left: .5rem
                        padding-right: .5rem
                        padding-top: .25rem
                        padding-bottom: .25rem
            .bracketsContainerContent
                height: 80%
                width: 98%
            .bracketsContainerVolleyball
                font-size: 1rem

@media screen and (max-width: 62rem)
    .brackets
        margin-top: 3rem
        .bracketsContainer
            background: rgba(0, 88, 94, 0.85)
            -webkit-backdrop-filter: blur(.25rem)
            backdrop-filter: blur(.25rem)
            box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, 0.3)
            width: 90%
            height: 42.5rem
            .bracketsContainerNav
                flex-direction: column
                padding-top: .85rem
                padding-bottom: .85rem
                gap: .65rem
                .bracketsContainerNavButtons1
                    gap: 3rem
                    .bracketsBtn1
                        min-width: 7.5rem
                        height: 2rem
                        font-size: 1rem
                        padding-left: 1.25rem
                        padding-right: 1.25rem
                .bracketsContainerNavButtons2
                    gap: 3rem
                    .bracketsBtn2
                        min-width: 9.5rem
                        height: 2rem
                        font-size: 1rem
                        padding-left: 1rem
                        padding-right: 1rem
            .bracketsContainerContent
                background: white
                box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, 0.4)
                height: 82%
                width: 93%
                margin-top: 0rem
                border-top-left-radius: 0rem
                border-top-right-radius: 0rem
                border-bottom-left-radius: 1.5rem
                border-bottom-right-radius: 1.5rem
            .bracketsContainerVolleyball
                color: black
                padding: 5rem
                font-size: 1.5rem

@media screen and (max-width: 45rem)
    .brackets
        .bracketsContainer
            height: 35.5rem
            .bracketsContainerNav
                padding-top: .7rem
                padding-bottom: .7rem
                .bracketsContainerNavButtons1
                    gap: 1.75rem
                    .bracketsBtn1
                        min-width: 6.75rem
                        height: 1.75rem
                        font-size: .9rem
                        padding-left: .75rem
                        padding-right: .75rem
                .bracketsContainerNavButtons2
                    gap: 1.5rem
                    .bracketsBtn2
                        min-width: 8.25rem
                        height: 1.75rem
                        font-size: .9rem
                        padding-left: .75rem
                        padding-right: .75rem
            .bracketsContainerContent
                height: 80%
                width: 92%
                padding: .75rem
            .bracketsContainerVolleyball
                font-size: 1.25rem
                padding: 3rem

@media screen and (max-width: 32rem)
    .brackets
        .bracketsContainer
            height: 34rem
            .bracketsContainerNav
                padding-top: .6rem
                padding-bottom: .6rem
                .bracketsContainerNavButtons1
                    gap: .5rem
                    .bracketsBtn1
                        min-width: 5.5rem
                        height: 1.5rem
                        font-size: .8rem
                        padding-left: .25rem
                        padding-right: .25rem
                        padding-top: .15rem
                        padding-bottom: .15rem
                .bracketsContainerNavButtons2
                    gap: 1rem
                    .bracketsBtn2
                        min-width: 6.5rem
                        height: 1.5rem
                        font-size: .8rem
                        padding-left: .25rem
                        padding-right: .25rem
                        padding-top: .15rem
                        padding-bottom: .15rem
            .bracketsContainerContent
                height: 83%
                width: 90%
            .bracketsContainerVolleyball
                font-size: 1rem
                padding: 2rem
