.notificationHelpModalBtn
    position: absolute
    display: table
    z-index: 11
    .notificationButton
        display: flex
        background: none
        border: none
        justify-content: center
        padding: 0
        .notificationHelpBtnText
            display: flex
            position: absolute
            font-family: 'Montserrat', sans-serif
            text-align: center
            font-size: .75rem
            align-items: center
            justify-content: center
            height: 100%
            width: 100%
            z-index: 1
        .notificationHelpModalBtnImage
            position: relative