.match
    font-style: normal
    font-weight: 600
    font-size: 30px

    @media screen and (max-width: 1400px)
        width: 100%
        position: relative
    @media screen and (max-width: 1200px)
        font-size: 20px
    @media screen and (max-width: 768px)
        width: 30vw
        font-size: 15px

    .dark-green
        background-color: #00585E
    .light-green
        background-color: #009494
    .yellow
        background-color: #FF5729

    .name
        color: #474647
    .score
        color: white
        text-align: center

    .borders
        border: 1px solid #00585E
        border-radius: 10px !important
    .borders-top
        border-top: 1px solid #00585E
    .borders-left
        outline: 1px solid #00585E
        // border-radius: 10px !important

    .cut-overflow
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        max-width: 100%

    .match-name
        font-size: 15px
        font-weight: 400
        font-style: italic
        margin-left: -0.5em