.map-div
    z-index: 0
    position: absolute
    left: 0
    top: 0
    overflow: hidden

.map-container
    height: 100vh
    width: 100vw
    margin: 0

    .mapboxgl-popup
        max-width: none !important

        .mapboxgl-popup-content
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: center
            padding: .75rem
            max-height: 15rem
            overflow-y: scroll
            border-radius: 1.25rem

            &::-webkit-scrollbar
                width: .25rem

            &::-webkit-scrollbar-track
                border-radius: .5rem
                background-color: transparent
                margin-top: 1rem
                margin-bottom: 1rem

            &::-webkit-scrollbar-thumb
                background: rgba(131, 172, 172, 0.85)
                border-radius: .5rem
                border-color: rgba(153, 201, 201, 0.85)

            .mapboxgl-popup-header
                font-size: 1rem
                font-weight: 500
                font-family: 'Montserrat', sans-serif
        
        .mapboxgl-popup-close-button
            display: none

