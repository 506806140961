@font-face
    font-family: 'digital-clock'
    src: url('../../../fonts/DS-DIGIB.TTF')

.timerWrapper
    display: flex
    position: relative
    min-height: 12rem
    min-width: 36rem
    .timerPassive
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        position: absolute
        .timerPassiveWrapper
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            .timerPassiveColumn
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                .timerPassiveDigits
                    display: flex
                    flex-direction: row
                    justify-content: center
                    align-items: center
                    .timerPassiveDigit
                        max-height: 9rem
                        font-family: 'digital-clock'
                        color: rgba(124, 124, 124, .125)
                        font-size: 8rem
                        margin: 0rem
                .timerPassiveFrame
                    font-family: 'Montserrat', sans-serif
                    font-weight: 500
                    font-size: 1.75rem
                    margin: 0rem
                    color: rgba(255, 87, 41, 1)
            .timerPassiveSeparator
                font-family: 'digital-clock'
                color: rgba(69, 68, 69, .2)
                font-size: 5rem
                margin: 0rem

    .timerActive
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        position: absolute
        .timerActiveWrapper
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            .timerActiveColumn
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                .timerActiveDigits
                    display: flex
                    flex-direction: row
                    justify-content: center
                    align-items: center
                    .timerActiveDigit
                        max-height: 9rem
                        font-family: 'digital-clock'
                        color: rgba(255, 87, 41, 1)
                        font-size: 8rem
                        margin: 0rem
                    .isOne
                        margin-left: 2.125rem
                        margin-right: -.3rem
                .timerActiveFrame
                    font-family: 'Montserrat', sans-serif
                    font-weight: 500
                    font-size: 1.75rem
                    margin: 0rem
                    color: rgba(255, 87, 41, 1)
                    margin-left: -.1rem
            .timerActiveSeparator
                font-family: 'digital-clock'
                color: rgba(255, 87, 41, 1)
                font-size: 5rem
                margin: 0rem

@media screen and (max-width: 39.5rem)
    .timerWrapper
        min-height: 6rem
        min-width: 18rem
        .timerPassive
            .timerPassiveWrapper
                .timerPassiveColumn
                    .timerPassiveDigits
                        .timerPassiveDigit
                            max-height: 4.5rem
                            font-size: 4rem
                    .timerPassiveFrame
                        font-weight: 500
                        font-size: 1rem
                .timerPassiveSeparator
                    font-size: 2.5rem
        .timerActive
            .timerActiveWrapper
                .timerActiveColumn
                    .timerActiveDigits
                        .timerActiveDigit
                            max-height: 4.5rem
                            font-size: 4rem
                        .isOne
                            margin-left: 1.1rem
                            margin-right: -.2rem
                    .timerActiveFrame
                        font-weight: 500
                        font-size: 1rem
                .timerActiveSeparator
                    font-size: 2.5rem
