.group
    background: white
    border-radius: 2rem
    border: .2rem solid rgba(0, 88, 94, 1)
    overflow: hidden
    margin-bottom: 1rem
    box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, .2)

    .groupNav
        background: rgba(0, 88, 94, 1)
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        width: 100%
        height: 2.75rem

        .groupNavText
            text-align: center
            color: white
            font-size: 1.75rem
            font-family: 'Montserrat', sans-serif
            font-weight: 700

    .groupContainer
        overflow-x: scroll

        &::-webkit-scrollbar
            height: .25rem

        &::-webkit-scrollbar-track
            border-radius: .5rem
            background: rgba(0, 88, 94, .85)
            margin-left: .75rem
            margin-right: .75rem

        &::-webkit-scrollbar-thumb
            background: rgba(68, 71, 71, 0.85)
            border-radius: .5rem
            border-color: rgba(3, 50, 54, 0.85)

        .groupContainerTable
            display: inline-block
            width: 100%

            .groupContainerTableNav
                background: rgba(0, 148, 148, 1)
                display: flex
                flex-direction: row
                justify-content: start
                align-items: center
                height: 4rem

                .groupContainerTableNavCol
                    text-align: center
                    color: white
                    width: 16.7%
                    font-size: 1.5rem
                    font-family: 'Montserrat', sans-serif
                    font-weight: 600
                    line-height: 1.5rem

            .groupContainerTableRow
                display: flex
                flex-direction: row
                justify-content: start
                align-items: center
                height: 4rem

                &:nth-child(even)
                    background: rgba(0, 148, 148, .3)
                &:nth-child(odd)
                    background: rgba(255, 87, 41, .3)

                .groupContainerTableRowCol
                    text-align: center
                    color: black
                    width: 16.7%
                    font-size: 1.5rem
                    font-family: 'Montserrat', sans-serif
                    font-weight: 500

                .groupContainerTableRowContainer
                    display: flex
                    gap: .75rem
                    width: 16.7%
                    padding-left: 1%

                    .groupContainerTableRowContainerMark
                        background: rgba(255, 87, 41, 1)
                        display: flex
                        justify-content: center
                        align-items: center
                        border-radius: .4rem
                        font-size: 1.4rem
                        color: white
                        width: 1.75rem
                        height: 2rem
                        font-weight: 700

            .groupContainerTableWinner
                background: linear-gradient(to right, rgba(0, 148, 148, .4) 50%, rgba(85, 225, 50, 0.8) 125%) !important

@media screen and (max-width: 79rem)
    .group
        .groupNav
            height: 2.5rem
            .groupNavText
                font-size: 1.6rem
        .groupContainer
            .groupContainerTable
                .groupContainerTableNav
                    height: 3.25rem
                    .groupContainerTableNavCol
                        font-size: 1.3rem
                        line-height: 1.25rem
                .groupContainerTableRow
                    height: 3.25rem
                    .groupContainerTableRowCol
                        font-size: 1.3rem
                    .groupContainerTableRowContainer
                        gap: .5rem
                        .groupContainerTableRowContainerMark
                            border-radius: .3rem
                            font-size: 1.2rem
                            width: 1.5rem
                            height: 1.75rem

@media screen and (max-width: 59rem)
    .group
        .groupNav
            height: 2.25rem
            .groupNavText
                font-size: 1.4rem
        .groupContainer
            .groupContainerTable
                width: auto
                .groupContainerTableNav
                    height: 3rem
                    .groupContainerTableNavCol
                        width: 7.25rem
                        font-size: 1.1rem
                        line-height: 1.15rem
                .groupContainerTableRow
                    height: 3rem
                    padding-right: 3.25rem
                    .groupContainerTableRowCol
                        width: 7.25rem
                        font-size: 1.1rem
                    .groupContainerTableRowContainer
                        width: 8rem
                        .groupContainerTableRowContainerMark
                            font-size: 1rem

@media screen and (max-width: 45rem)
    .group
        .groupNav
            height: 2rem
            .groupNavText
                font-size: 1.2rem
        .groupContainer
            .groupContainerTable
                .groupContainerTableNav
                    height: 2.75rem
                    .groupContainerTableNavCol
                        width: 6rem
                        font-size: 1rem
                        line-height: 1rem
                .groupContainerTableRow
                    height: 2.75rem
                    padding-right: 2.5rem
                    .groupContainerTableRowCol
                        width: 6rem
                        font-size: 1rem
                    .groupContainerTableRowContainer
                        gap: .25rem
                        width: 6rem
                        .groupContainerTableRowContainerMark
                            font-size: .9rem
                            width: 1.25rem
                            height: 1.5rem

@media screen and (max-width: 32rem)
    .group
        .groupNav
            .groupNavText
                font-size: 1rem
        .groupContainer
            .groupContainerTable
                .groupContainerTableNav
                    gap: .25rem
                    .groupContainerTableNavCol
                        width: 4.5rem
                        font-size: .9rem
                        line-height: .9rem
                .groupContainerTableRow
                    padding-right: .5rem
                    gap: .25rem
                    .groupContainerTableRowCol
                        width: 4.5rem
                        font-size: .9rem
                    .groupContainerTableRowContainer
                        .groupContainerTableRowContainerMark
