.modal-dialog
    max-width: 80rem !important
    .modal-content
        display: flex
        padding-left: 3rem
        padding-right: 3rem
        padding-top: 2rem
        padding-bottom: 2rem
        background: white
        border-radius: 1rem
        color: black
        .modal-header
            border: 0
            justify-content: space-between
            .modal-title
                color: #00585E
                font-size: 3rem
                font-weight: 600
            .exitModalBtn
                background: none
                border: none
                .exitModalIcon
                    max-width: 3rem
            .wrapperLanguage
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center
                .languageBtn
                    display: flex
                    align-items: center
                    justify-content: center
                    background: rgba(223, 222, 222, .35)
                    border: 1px solid #d5d9d9
                    border-radius: .5rem
                    box-shadow: rgba(213, 217, 217, .5) 0rem .25rem .5rem 0rem
                    box-sizing: border-box
                    padding-top: .25rem
                    padding-bottom: .25rem
                    padding-left: .75rem
                    padding-right: .75rem
                    .languageIcon
                        max-width: 1.5rem
                        margin-right: .5rem
                    .languageText
                        font-size: 1.5rem
                        font-weight: 500
                .languageBtnHide
                    visibility: hidden

        .modal-body
            max-height: 35rem
            overflow-y: scroll
            .modalText
                display: flex
                flex-direction: column
                overflow-wrap: break-word
                font-size: 1rem
            &::-webkit-scrollbar
                width: .25rem

            &::-webkit-scrollbar-track
                border-radius: .5rem
                background-color: transparent
                margin-top: 1rem
                margin-bottom: 1rem

            &::-webkit-scrollbar-thumb
                background: rgba(0, 88, 94, .85)
                border-radius: .5rem
                border-color: rgba(0, 88, 94, .85)
        .modal-footer
            border: 0
        .btn-close
            filter: invert(1) grayscale(100%) brightness(200%)

@media screen and (max-width: 82rem)
    .modal-dialog
        max-width: 58rem !important
        .modal-content
            padding-left: 2.5rem
            padding-right: 2.5rem
            padding-top: 1.5rem
            padding-bottom: 1.5rem
            .modal-header
                .modal-title
                    font-size: 2.5rem
                    font-weight: 600
                .exitModalBtn
                    .exitModalIcon
                        max-width: 2.5rem
                .wrapperLanguage
                    .languageBtn
                        padding-left: .5rem
                        padding-right: .5rem
                        .languageIcon
                            max-width: 1.25rem
                            margin-right: .25rem
                        .languageText
                            font-size: 1.25rem
            .modal-body
                max-height: 25rem
                .modalText
                    font-size: .9rem


@media screen and (max-width: 59rem)
    .modal-dialog
        max-width: 44rem !important
        .modal-content
            padding-left: 2.25rem
            padding-right: 2.25rem
            padding-top: 1rem
            padding-bottom: 1rem
            .modal-header
                .modal-title
                    font-size: 2rem
                    font-weight: 600
                .exitModalBtn
                    .exitModalIcon
                        max-width: 2rem
                .wrapperLanguage
                    .languageBtn
                        padding-left: .5rem
                        padding-right: .5rem
                        .languageIcon
                            visibility: hidden
                            max-width: 0rem
                            margin-right: 0rem
                        .languageText
                            font-size: 1rem

            .modal-body
                max-height: 20rem
                .modalText
                    font-size: .75rem

@media screen and (max-width: 45rem)
    .modal-dialog
        max-width: 26rem !important
        .modal-content
            padding-left: 1.75rem
            padding-right: 1.75rem
            padding-top: .75rem
            padding-bottom: .75rem
            .modal-header
                padding-left: 0rem
                padding-right: 0rem
                .modal-title
                    text-align: center
                    font-size: 1.5rem
                    font-weight: 600
                .exitModalBtn
                    .exitModalIcon
                        max-width: 1.75rem
                .wrapperLanguage
                    .languageBtn
                        .languageText
                            font-size: .85rem
            .modal-body
                max-height: 30rem
                .modalText
                    font-size: .65rem
