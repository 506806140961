.rulesContainer
    min-height: 78vh
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 2rem
    .rulesContainerMobile
        justify-content: center
        align-items: center
        display: grid
        grid-auto-flow: column
        gap: 2rem
        padding-left: 1rem
        padding-right: 2rem
        .rulesContainerMobileCard
            display: flex     
            position: relative
            &:nth-child(even)
                max-height: 95%
            &:nth-child(2)
                .rulesSloth1
                    display: block
            &:nth-child(5)
                .rulesSloth2
                    display: block
            .cardContainer
            .rulesSloth1
                display: none
            .rulesSloth2
                display: none

@media screen and (max-width: 91rem)
    .rulesContainer
        .rulesContainerMobile
            grid-auto-flow: row
            grid-template: repeat(1, 1fr) / repeat(3, 1fr)
            .rulesContainerMobileCard:nth-child(even)
                max-height: 100%
                .cardContainer

@media screen and (max-width: 59rem)
    .rulesContainer
        min-height: 85vh
        display: flex
        flex-direction: column
        .rulesSwitchBarMobile
            row-gap: .5rem
            column-gap: .5rem
            padding-left: 1rem
            padding-right: 1rem
            padding-bottom: .5rem
            padding-top: .5rem
            margin-bottom: -1.25rem
            background: rgba(0, 148, 148, 1)
            border-radius: 1.5rem
            display: grid
            grid-auto-flow: row
            grid-template: repeat(1, 1fr) / repeat(3, 1fr)
            align-items: center
            justify-content: center
            z-index: 1
            .rulesSwitchBarBtn
                width: 6rem
                height: 1.5rem
                padding: 0rem
                font-size: .65rem
                font-weight: 600
                &.active
                    color: rgba(255, 87, 41, 1) !important
        .rulesContainerMobile
            grid-template: none
            gap: 0rem
            background: rgba(0, 88, 94, .85)
            -webkit-backdrop-filter: blur(.25rem)
            backdrop-filter: blur(.25rem)
            padding-left: 2rem
            padding-right: 2rem
            padding-top: 2.5rem
            padding-bottom: 2.5rem
            border-radius: 1.5rem
