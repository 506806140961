.game
    background: rgba(214, 250, 250, 1)
    border-radius: 1.5rem
    padding: .5rem
    margin: .5rem
    box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.3)
    position: relative

    .game-live
        position: absolute
        font-size: .6rem
        top: 2%
        left: 7%
        color: red

    .game-content
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        min-width: 20rem

        .game-content-left
            display: flex
            flex-direction: row
            justify-content: center
            align-content: center
            width: 50%

        .game-content-teams
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            
            .game-content-teams-team
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 50%
                font-family: 'Montserrat', sans-serif
                text-align: center
                word-break: break-word
                color: rgba(69, 68, 69, 1)
                font-size: 1.15rem
                font-weight: 500

        .game-content-right
            display: flex
            flex-direction: row
            justify-content: center
            align-content: center
            width: 50%

            .game-content-date
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 50%
                font-family: 'Montserrat', sans-serif
                text-align: center
                color: rgba(69, 68, 69, 1)
                font-size: 1.15rem
                font-weight: 500

            .game-content-score
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 50%

                .game-content-score-text
                    font-family: 'Montserrat', sans-serif
                    text-align: center
                    color: rgba(69, 68, 69, 1)
                    font-weight: 500
                    .game-content-score-text-match
                        font-size: 1.35rem
                    .game-content-score-text-row
                        display: flex
                        flex-direction: row
                        justify-content: center
                        align-items: center
                        // gap: .25rem
                        // height: 1.65rem
                        .game-content-score-text-sets
                            // font-size: 1.65rem

.marked
    background: rgba(235, 156, 32, 0.9)

@media screen and (max-width: 78rem)
    .game
        .game-live
        .game-content
            min-width: 20rem
            .game-content-left
            .game-content-teams
                .game-content-teams-team
                    font-size: 1.1rem
            .game-content-right
                .game-content-date
                    font-size: 1.1rem
                .game-content-score
                    .game-content-score-text
                        .game-content-score-text-match
                            font-size: 1.25rem
                        .game-content-score-text-row
                            // height: 1.5rem
                            .game-content-score-text-sets
                                // font-size: 1.5rem

        
@media screen and (max-width: 59rem)
    .game
        .game-live
        .game-content
            min-width: 19rem
            .game-content-left
            .game-content-teams
                .game-content-teams-team
                    width: 100%
                    font-size: 1rem
            .game-content-right
                .game-content-date
                    font-size: 1rem
                .game-content-score
                    .game-content-score-text
                        .game-content-score-text-match
                            font-size: 1.15rem
                        .game-content-score-text-row
                            .game-content-score-text-sets
                                // font-size: 1.3rem

@media screen and (max-width: 45rem)
    .game
        .game-live
            font-size: .55rem
        .game-content
            min-width: 15rem
            .game-content-left
                width: 40%
            .game-content-teams
                .game-content-teams-team
                    font-size: .9rem
            .game-content-right
                width: 60%
                .game-content-date
                    font-size: .9rem
                .game-content-score
                    .game-content-score-text
                        .game-content-score-text-match
                            font-size: 1.1rem
                        .game-content-score-text-row
                            // height: 1.25rem
                            .game-content-score-text-sets
                                // font-size: 1.2rem

@media screen and (max-width: 32rem)
    .game
        .game-live
            left: 8%
        .game-content
            min-width: 13rem
            .game-content-left
                width: 45%
            .game-content-teams
                flex-direction: column
                line-height: .75rem
                .game-content-teams-team
                    font-size: .8rem
            .game-content-right
                width: 55%
                .game-content-date
                    flex-direction: column
                    font-size: .85rem
                .game-content-score
                    .game-content-score-text
                        flex-direction: column
                        .game-content-score-text-match
                            font-size: 1rem
                        .game-content-score-text-row
                            // height: 1.2rem
                            .game-content-score-text-sets
                                // font-size: 1rem

