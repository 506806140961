.personContainer
    background: rgba(0, 148, 148, 0.09)
    display: inline-flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    padding: .5rem
    border-radius: 1rem
    box-shadow: 0rem .15rem .25rem 0rem rgba(0, 0, 0, 0.15)
    margin: .2rem

    .personImg
        box-shadow: .1rem .1rem .1rem .1rem rgba(0, 0, 0, 0.15)
        max-width: 30%
        max-height: 60%
        border-radius: 100%

    .personDetails
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 75%

        .personDetailsName
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            text-align: center
            font-family: 'Montserrat', sans-serif
            font-weight: 500
            font-size: 1rem
            line-height: 1.5rem
            color: rgba(69, 68, 69, 1)

@media screen and (max-width: 78rem)
    .personContainer
        .personImg
            max-width: 30%
        .personDetails
            max-width: 70%
            .personDetailsName
                font-size: .9rem
                line-height: 1.3rem

@media screen and (max-width: 59rem)
    .personContainer
        background: rgba(0, 148, 148, 0.47)
        flex-direction: column
        justify-content: center
        padding-top: 1rem
        padding-bottom: 1.5rem
        margin: .4rem
        gap: 2rem
        .personImg
            max-width: 50%
            max-height: 40%
        .personDetails
            width: 100%
            .personDetailsName
                margin-left: 0rem
                margin-right: 0rem
                font-size: 1rem
                line-height: 1.2rem

@media screen and (max-width: 45rem)
    .personContainer
        padding-top: .75rem
        padding-bottom: 1.25rem
        gap: 1.5rem
        .personImg
        .personDetails
            .personDetailsName
                font-size: .9rem
                line-height: 1.1rem

@media screen and (max-width: 32rem)
    .personContainer
        padding-bottom: 1.5rem
        gap: 2rem
        .personImg
        .personDetails
            .personDetailsName
                font-size: .8rem
                line-height: 1rem