.gamerow
    background: rgba(214, 250, 250, 1)
    border-radius: 1.5rem
    padding: 1rem
    margin: 1rem
    box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.3)

    .gamerow-content
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center

        .gamerow-content-left
            display: flex
            flex-direction: row
            justify-content: center
            align-content: center
            width: 60%

        .gamerow-content-teams
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            
            .gamerow-content-teams-team
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 50%
                font-family: 'Montserrat', sans-serif
                text-align: center
                color: rgba(69, 68, 69, 1)
                font-size: 1.75rem
                font-weight: 500

        .gamerow-content-right
            display: flex
            flex-direction: row
            justify-content: center
            align-content: center
            width: 40%

            .gamerow-content-date
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 50%
                font-family: 'Montserrat', sans-serif
                text-align: center
                color: rgba(69, 68, 69, 1)
                font-size: 1.75rem
                font-weight: 500

            .gamerow-content-score
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 50%

                .gamerow-content-score-text
                    font-family: 'Montserrat', sans-serif
                    text-align: center
                    color: rgba(69, 68, 69, 1)
                    font-weight: 500
                    .gamerow-content-score-text-match
                        font-size: 2rem
                    .gamerow-content-score-text-row
                        display: flex
                        flex-direction: row
                        justify-content: center
                        align-items: center
                        gap: .25rem
                        height: 1.65rem
                        .gamerow-content-score-text-sets
                            font-size: 1.65rem

@media screen and (max-width: 78rem)
    .gamerow
        .gamerow-content
            .gamerow-content-left
            .gamerow-content-teams
                .gamerow-content-teams-team
                    font-size: 1.5rem
            .gamerow-content-right
                .gamerow-content-date
                    font-size: 1.45rem
                .gamerow-content-score
                    .gamerow-content-score-text
                        .gamerow-content-score-text-match
                            font-size: 1.7rem
                        .gamerow-content-score-text-row
                            height: 1.5rem
                            .gamerow-content-score-text-sets
                                font-size: 1.5rem

        
@media screen and (max-width: 59rem)
    .gamerow
        .gamerow-content
            .gamerow-content-left
            .gamerow-content-teams
                .gamerow-content-teams-team
                    font-size: 1.25rem
            .gamerow-content-right
                .gamerow-content-date
                    font-size: 1.25rem
                .gamerow-content-score
                    .gamerow-content-score-text
                        .gamerow-content-score-text-match
                            font-size: 1.5rem
                        .gamerow-content-score-text-row
                            .gamerow-content-score-text-sets
                                font-size: 1.3rem

@media screen and (max-width: 45rem)
    .gamerow
        .gamerow-content
            .gamerow-content-left
            .gamerow-content-teams
                .gamerow-content-teams-team
                    font-size: 1rem
            .gamerow-content-right
                .gamerow-content-date
                    font-size: 1rem
                .gamerow-content-score
                    .gamerow-content-score-text
                        .gamerow-content-score-text-match
                            font-size: 1.25rem
                        .gamerow-content-score-text-row
                            height: 1.25rem
                            .gamerow-content-score-text-sets
                                font-size: .85rem

@media screen and (max-width: 32rem)
    .gamerow
        .gamerow-content
            .gamerow-content-left
                width: 50%
            .gamerow-content-teams
                flex-direction: column
                .gamerow-content-teams-team
                    font-size: 1rem
            .gamerow-content-right
                width: 50%
                .gamerow-content-date
                    flex-direction: column
                    font-size: .9rem
                .gamerow-content-score
                    .gamerow-content-score-text
                        flex-direction: column
                        .gamerow-content-score-text-match
                            font-size: 1rem
                        .gamerow-content-score-text-row
                            height: 1.2rem
                            .gamerow-content-score-text-sets
                                font-size: .6rem

