$padding-top:0.5rem
$padding-left: 4rem
$color: #00585E
$background: #FFF
$color_rev: #FFF
$background_rev: #00585E


.inputContainer
  font-family: 'Montserrat', sans-serif
  font-weight: 500
  position: relative
  margin: 5px 0
  color: $color

  input
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    height: 50px
    box-sizing: border-box
    padding: 0.7rem 1.5rem 0.3rem 4rem
    background: $background
    border-radius: 12px
    width: 100%
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.75)
    border: none
    color: $color

    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &:focus-visible
      outline: (1px solid $color) // parantheses are set to stop editor from complaining for nothing
  &:after
    content: attr(floatingtext)
    position: absolute
    color: $color
    left: $padding-left - 0.4rem
    top: 50%
    transform: translateY(-50%)
    font-size: 12pt
    padding: 0 0 0 8px !important
    transition: 200ms ease-in-out
    text-transform: capitalize
  .icon
    padding: 0.7rem 0 0.3rem 0
    position: absolute
    left: $padding-left / 3
    top: 50%
    transform: translateY(-50%)
    font-size: 16pt

  &.profile
    width:100%
    margin-bottom: 15px
    
    input
      font-family: 'Montserrat', sans-serif
      font-weight: 500
      box-shadow: 0 .5rem .5rem rgba(0, 0, 0, .25)
      outline: (1px solid $color) // parantheses are set to stop editor from complaining for nothing
.inputContainer:hover:after, .hasInput:after
  font-size: 8pt
  padding-bottom: 6px
  top: 20%
.reverse
  color: $color_rev
  input
    color: $color_rev
    background: $background_rev
  &:after
    color: $color_rev

input:-webkit-autofill
  &,
  &:hover,
  &:focus,
  &:active
    transition-delay: 9999s
    transition-property: background-color, color

@media screen and (max-width: 768px)
  input
    font-size: 0.9em !important
