.bracketsHelper
    background-color: #ffe6df
    border-radius: 40px
    padding-left: 2.25rem
    padding-right: 2.25rem
    padding-top: 2rem
    padding-bottom: 2rem
    box-shadow: 0rem .3rem .5rem 0rem rgba(0, 0, 0, .4)
    @media screen and (max-width: 45rem)
        padding-left: 1.5rem
        padding-right: 1.5rem
        padding-top: 1rem
        padding-bottom: 1rem

    .bracketsHelperTitle
        text-align: center
        font-family: 'Montserrat', sans-serif
        font-size: 3rem
        font-weight: 700
        color: rgba(0, 118, 121, 1)
        margin-bottom: 2rem
        @media screen and (max-width: 78rem)
            font-size: 2.5rem
        @media screen and (max-width: 59rem)
            font-size: 2.25rem
        @media screen and (max-width: 45rem)
            font-size: 1.75rem

    .bracketsHelperGap
        gap: 2rem
        @media screen and (max-width: 59rem)
            gap: 0rem

    .match
        max-width: 25vw

    .bracket
        padding: 0rem

    .bracketType
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        font-size: .9rem
        font-weight: 400
        font-style: italic
        padding: 0rem
        margin-top: -.25rem
        width: 95%
        margin-left: -.25rem
        @media screen and (max-width: 61.9rem)
            width: 39%
            margin-left: .75rem
        @media screen and (max-width: 45rem)
            margin-left: .5rem
        @media screen and (max-width: 32rem)
            width: 40%
            margin-left: -.1rem
        
    .bracketHelperContainer
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column

    .flexy
        display: flex
        justify-content: center
        flex-direction: column
    .flexx
        display: flex
        justify-content: center
        flex-direction: row
    .final::before
        @media screen and (max-width: 59rem)
            content: "-"
            display: block
            color: white
            width: 50%
            border-right: 1px solid #00585E

    .none
        display: none
        @media screen and (max-width: 59rem)
            display: block
    .none::after
        @media screen and (max-width: 59rem)
            content: "-"
            display: block
            color: white
            margin-left: 25%
            width: 50%
            border: 1px solid #00585E
            border-top: none !important