#root:has(.groups)
    display: block // This keeps the whole container not to overflow in the #body element in width

.groups
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: 100vh
    padding-bottom: 2rem

    .groupsContainer
        background-color: white
        height: 45rem
        width: 90%
        border-radius: 2rem
        box-shadow: 0rem 0rem 1.25rem 0rem rgba(0, 0, 0, 0.3)

        .groupsContainerNav
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            background-color: rgba(255, 87, 41, 1)
            border-top-left-radius: 2rem
            border-top-right-radius: 2rem
            padding-top: 1.25rem
            padding-bottom: 1.25rem
            gap: 4rem

            .groupsContainerNavButtons1
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 4rem

                .groupsBtn1
                    min-width: 10rem
                    height: 3rem
                    font-size: 1.2rem
                    text-transform: none
                    padding-left: 2rem
                    padding-right: 2rem
                    padding-top: .5rem
                    padding-bottom: .5rem

            .groupsContainerNavButtons2
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                gap: 4rem

                .groupsBtn2
                    min-width: 10rem
                    height: 3rem
                    font-size: 1.2rem
                    text-transform: none
                    padding-left: .25rem
                    padding-right: .25rem
                    padding-top: .5rem
                    padding-bottom: .5rem

        .groupsContainerContent
            height: 82%
            width: 97%
            margin: 1.25rem auto
            padding: 1rem
            background-image: url("../../images/backgrounds/background-faq.svg")
            background-repeat: no-repeat
            background-size: cover
            border-radius: 3rem
            overflow-y: auto

            &::-webkit-scrollbar
                width: .25rem

            &::-webkit-scrollbar-track
                border-radius: .5rem
                margin-top: 3rem
                margin-bottom: 3rem

            &::-webkit-scrollbar-thumb
                background: rgba(0, 88, 94, .85)
                border-radius: .5rem
                border-color: rgba(0, 88, 94, .85)

        .groupsContainerVolleyball
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            text-align: center
            color: rgba(255, 255, 255, 1)
            font-weight: 600
            padding: 10rem
            font-size: 2rem

@media screen and (max-width: 78rem)
    .groups
        min-height: 78vh
        .groupsContainer
            height: 40rem
            .groupsContainerNav
                gap: 1.75rem
                .groupsContainerNavButtons1
                    gap: 1.75rem
                    .groupsBtn1
                        min-width: 9rem
                        height: 2.5rem
                        font-size: 1.1rem
                        padding-left: 1.75rem
                        padding-right: 1.75rem
                        padding-top: .25rem
                        padding-bottom: .25rem
                .groupsContainerNavButtons2
                    gap: 1.75rem
                    .groupsBtn2
                        min-width: 9rem
                        height: 2.5rem
                        font-size: 1.1rem
                        padding-left: .5rem
                        padding-right: .5rem
                        padding-top: .25rem
                        padding-bottom: .25rem
            .groupsContainerContent
                height: 82%
                width: 95%

            .groupsContainerVolleyball
                padding: 7.5rem
                font-size: 1.5rem

@media screen and (max-width: 59rem)
    .groups
        margin-top: 3rem
        .groupsContainer
            background: rgba(0, 88, 94, 0.85)
            -webkit-backdrop-filter: blur(.25rem)
            backdrop-filter: blur(.25rem)
            box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, 0.3)
            .groupsContainerNav
                flex-direction: column
                padding-top: .85rem
                padding-bottom: .85rem
                gap: .65rem
                .groupsContainerNavButtons1
                    gap: 3rem
                    .groupsBtn1
                        min-width: 7.5rem
                        height: 2rem
                        font-size: 1rem
                        padding-left: 1.25rem
                        padding-right: 1.25rem
                .groupsContainerNavButtons2
                    gap: 3rem
                    .groupsBtn2
                        min-width: 9.5rem
                        height: 2rem
                        font-size: 1rem
                        padding-left: 1rem
                        padding-right: 1rem
            .groupsContainerContent
                background: white
                box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, 0.4)
                height: 81%
                width: 93%
                margin-top: 0rem
                border-top-left-radius: 0rem
                border-top-right-radius: 0rem
                border-bottom-left-radius: 1.5rem
                border-bottom-right-radius: 1.5rem

                &::-webkit-scrollbar-track
                    margin-top: 0rem
                    margin-bottom: 1rem
            .groupsContainerVolleyball
                color: rgba(0, 0, 0, 1)
                padding: 5rem
                font-size: 1.25rem

@media screen and (max-width: 45rem)
    .groups
        .groupsContainer
            .groupsContainerNav
                padding-top: .7rem
                padding-bottom: .7rem
                .groupsContainerNavButtons1
                    gap: 1.75rem
                    .groupsBtn1
                        min-width: 6.75rem
                        height: 1.75rem
                        font-size: .9rem
                        padding-left: .75rem
                        padding-right: .75rem
                .groupsContainerNavButtons2
                    gap: 1.5rem
                    .groupsBtn2
                        min-width: 8.25rem
                        height: 1.75rem
                        font-size: .9rem
                        padding-left: .75rem
                        padding-right: .75rem
            .groupsContainerContent
                height: 83%
                width: 92%
                padding: .75rem
            .groupsContainerVolleyball
                padding: 3.5rem
                font-size: 1.1rem

@media screen and (max-width: 32rem)
    .groups
        .groupsContainer
            height: 37rem
            .groupsContainerNav
                padding-top: .6rem
                padding-bottom: .6rem
                .groupsContainerNavButtons1
                    gap: .5rem
                    .groupsBtn1
                        min-width: 5.5rem
                        height: 1.5rem
                        font-size: .8rem
                        padding-left: .25rem
                        padding-right: .25rem
                        padding-top: .15rem
                        padding-bottom: .15rem
                .groupsContainerNavButtons2
                    gap: 1rem
                    .groupsBtn2
                        min-width: 6.5rem
                        height: 1.5rem
                        font-size: .8rem
                        padding-left: .25rem
                        padding-right: .25rem
                        padding-top: .15rem
                        padding-bottom: .15rem
            .groupsContainerContent
                height: 84%
                width: 90%
            .groupsContainerVolleyball
                padding: 1.25rem
                font-size: 1rem
