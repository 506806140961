.livescore-card
  background-image: url("../../../images/backgrounds/background-live-match-card.svg")
  background-repeat: no-repeat
  background-size: cover
  border-radius: 2rem
  color: white
  font-family: 'Montserrat', sans-serif
  font-weight: 500

  height: 18rem
  display: flex
  justify-content: space-between

  .livescore-card-title-wrapper
    display: flex
    justify-content: center
    align-items: center

  .card-footer,
  .card-header
    border: none
    background-color: transparent

    .flex33
      flex: 33%
      max-width: 33% !important

  .logo-container
    display: flex
    align-items: center
    flex-direction: column

    .team-logo
      height: 5rem
      width: 5rem
      border-radius: 50%
      background-color: white

  .score
    margin: auto
    background-color: #FF5729
    border-radius: .75rem
    border: 1px solid white
    font-size: 1.5rem
    font-weight: 600

    @media (max-width: 59rem)
      font-size: 1rem

  .gridc
    display: grid
    grid-template-columns: 2fr repeat(var(--columns), 0.5fr)
    grid-template-rows: repeat(auto-fill, 1fr)

    .gridi
      display: inline-block
      border: 1px solid white
      text-align: center
      font-size: 1.25rem
      z-index: 1
      padding: 0 .5rem

      @media (max-width: 59rem)
        font-size: 1rem
