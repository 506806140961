.wrapperNotificationHelp
    display: flex
    flex-direction: column
    gap: .5rem
    .notificationHelpText
        font-size: 1rem
        font-weight: 500
        line-height: .7rem
        text-align: center
        color: red
    .notificationHelpLeft
        display: flex
        flex-direction: row
        justify-content: center
        gap: 1rem
        height: 3rem
    .notificationHelpRight
        display: flex
        flex-direction: row
        justify-content: center
        gap: 1rem
        height: 3rem

    .buttonContainer
        filter: none
        flex: none

.notificationHelpBtn
    width: 40%
    text-transform: none

@media screen and (max-width: 82rem)
    .wrapperNotificationHelp
        gap: .75rem
        .notificationHelpText
            font-size: .9rem
    .notificationHelpBtn
        width: 47%

@media screen and (max-width: 59rem)
    .wrapperNotificationHelp
        gap: .75rem
        .notificationHelpText
            font-size: .8rem
        .notificationHelpLeft
            flex-direction: column
            align-items: center
            gap: .75rem
            height: 100%
        .notificationHelpRight
            flex-direction: column
            align-items: center
            gap: .75rem
            height: 100%
    .notificationHelpBtn
        width: 70%
        height: 4rem

@media screen and (max-width: 45rem)
    .wrapperNotificationHelp
        .notificationHelpText
            font-size: .7rem
    .notificationHelpBtn
        width: 100%