.facultySportStats
    background: white
    border-radius: 2rem
    border: .2rem solid rgba(0, 88, 94, 1)
    overflow: hidden
    margin-bottom: 1rem
    box-shadow: 0rem .25rem .25rem 0rem rgba(0, 0, 0, .2)

    .facultySportStatsNav
        background: rgba(0, 88, 94, 1)
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        width: 100%
        height: 2.75rem

        .facultySportStatsNavText
            text-align: center
            color: white
            font-size: 1.75rem
            font-family: 'Montserrat', sans-serif
            font-weight: 700

    .facultySportStatsContainer
        overflow-x: scroll

        &::-webkit-scrollbar
            height: .25rem

        &::-webkit-scrollbar-track
            border-radius: .5rem
            background: rgba(0, 88, 94, .85)
            margin-left: .75rem
            margin-right: .75rem

        &::-webkit-scrollbar-thumb
            background: rgba(68, 71, 71, 0.85)
            border-radius: .5rem
            border-color: rgba(3, 50, 54, 0.85)

        .facultySportStatsContainerTable
            display: inline-block
            width: 100%

            .facultySportStatsContainerTableRow
                display: flex
                flex-direction: row
                justify-content: start
                align-items: center
                height: 4rem

                &:nth-child(odd)
                    background: rgba(0, 148, 148, .3)
                &:nth-child(even)
                    background: rgba(255, 87, 41, .3)

                .facultySportStatsContainerTableRowCol
                    text-align: center
                    color: black
                    width: 25%
                    font-size: 1.5rem
                    font-family: 'Montserrat', sans-serif
                    font-weight: 500

                .facultySportStatsContainerTableRowColPS
                    display: flex
                    flex-direction: row
                    justify-content: center
                    align-items: center
                    .facultySportStatsPartialScore
                        width: max-content
                        background: rgba(0, 88, 94, .7)
                        border-radius: 1rem
                        padding: .25rem
                            
                            

@media screen and (max-width: 79rem)
    .facultySportStats
        .facultySportStatsNav
            height: 2.5rem
            .facultySportStatsNavText
                font-size: 1.6rem
        .facultySportStatsContainer
            .facultySportStatsContainerTable
                .facultySportStatsContainerTableRow
                    height: 3.25rem
                    .facultySportStatsContainerTableRowCol
                        font-size: 1.3rem

@media screen and (max-width: 59rem)
    .facultySportStats
        .facultySportStatsNav
            height: 2.25rem
            .facultySportStatsNavText
                font-size: 1.4rem
        .facultySportStatsContainer
            .facultySportStatsContainerTable
                width: auto
                .facultySportStatsContainerTableRow
                    height: 3rem
                    .facultySportStatsContainerTableRowCol
                        width: 12rem
                        font-size: 1.1rem


@media screen and (max-width: 45rem)
    .facultySportStats
        .facultySportStatsNav
            height: 2rem
            .facultySportStatsNavText
                font-size: 1.2rem
        .facultySportStatsContainer
            .facultySportStatsContainerTable
                .facultySportStatsContainerTableRow
                    height: 2.75rem
                    .facultySportStatsContainerTableRowCol
                        width: 9rem
                        font-size: 1rem

@media screen and (max-width: 32rem)
    .facultySportStats
        .facultySportStatsNav
            .facultySportStatsNavText
                font-size: 1rem
        .facultySportStatsContainer
            .facultySportStatsContainerTable
                .facultySportStatsContainerTableRow
                    .facultySportStatsContainerTableRowCol
                        width: 6rem
                        font-size: .9rem
