.confirmContainer
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    .confirmBox
        margin-left: 20%
        margin-right: 20%
        text-align: center
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25)
        border-radius: 30px
        align-items: center
        background: white
        min-width: 20%
        font-size: 30px
        color: #454445
