.alert-color
  color: #DC143C

.loginContainer
  color: #FFFFFF
  height: 100%
  display: flex
  align-items: center
  flex-direction: column
  padding: 1rem 1rem 0rem 1rem
  .formBody
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    flex: 1 1
    padding-top: 2rem
    h1
        text-align: center
        margin-bottom: 2rem
        padding-top: 0
        font-family: 'Montserrat', sans-serif
        font-weight: 600
  a
    color: #FFFFFF
    text-decoration: none
  .signinBtn
    padding-left: 2.5rem
    padding-right: 2.5rem

.buttonContainer
  flex: 0 0
  padding-left: 2rem
  padding-right: 2rem
  font-weight: 500
  filter: drop-shadow(0rem .25rem 1.25rem rgba(0, 0, 0, 0.25))

.inputs
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 1rem
  margin-bottom: 30%
  .inputContainer
    margin-top: 1.5rem
    width: 80%
  .forgotPass
    margin-top: 1rem
    font-family: 'Montserrat', sans-serif
    font-weight: 500
    

@media screen and (max-width: 59.5rem)
  .inputs
    margin-top: 0rem
    margin-bottom: 0
    .inputContainer
      margin-top: 1rem
      width: 100%
    .forgotPass
        margin-top: 0rem
        font-family: 'Montserrat', sans-serif
        font-weight: 500

  .loginContainer
    gap: 1rem
    .formBody
      padding-top: 0rem
      padding-left: 0rem
      padding-right: 0rem
      gap: 1.25rem
      h1
        margin-bottom: 1rem
        font-family: 'Montserrat', sans-serif
        font-weight: 600

    .buttonContainer
        margin-top: 10%
